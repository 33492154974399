import apiCall from '@/utils/api'

const state = {
	partLearningAreas: [],
	allPartLearningAreas: [],
    partLearningAreaPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    partLearningAreaLoader: false
};

const getters = {
	partLearningAreas: (state) => state.partLearningAreas,
	allPartLearningAreas: (state) => state.allPartLearningAreas,
    partLearningAreaLoader: (state) => state.partLearningAreaLoader,
    partLearningAreaPagination: (state) => state.partLearningAreaPagination
}

const actions = {
	async fetchAllPartLearningAreas({commit}, page) {
		const response = await apiCall({url: `/api/partLearningArea?type=all`, method: 'GET' });
		commit('setAllPartLearningAreas', response)
        commit('stopLoader', response)
	},
    async fetchPartLearningAreas({commit}, page) {
		const response = await apiCall({url: `/api/partLearningArea?page=${page}`, method: 'GET' });
		commit('setPartLearningAreas', response)
        commit('stopLoader', response)
	},
    async filterPartLearningAreas({commit, state},resp){
		commit('setPartLearningAreas', resp)
	},
	async startPartLearningAreaLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPartLearningAreaLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPartLearningAreas: (state, partLearningAreas) => {
		state.partLearningAreas = partLearningAreas.data
		state.partLearningAreaPagination.current_page = partLearningAreas.current_page
		state.partLearningAreaPagination.total = partLearningAreas.total
		state.partLearningAreaPagination.per_page = partLearningAreas.per_page
	},
	setAllPartLearningAreas: (state, partLearningAreas) => {
		state.allPartLearningAreas = partLearningAreas
	},
    startLoader: (state) => state.partLearningAreaLoader = true,
	stopLoader: (state) => state.partLearningAreaLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
