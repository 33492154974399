import apiCall from '@/utils/api'

const state = {
	stocks: [],
	allStocks: [],
    stockPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    stockLoader: false
};

const getters = {
	stocks: (state) => state.stocks,
	allStocks: (state) => state.allStocks,
    stockLoader: (state) => state.stockLoader,
    stockPagination: (state) => state.stockPagination
}

const actions = {
	async fetchAllStocks({commit}, page) {
		const response = await apiCall({url: `/api/stock?type=all`, method: 'GET' });
		commit('setAllStocks', response)
        commit('stopLoader', response)
	},
    async fetchStocks({commit}, page) {
		const response = await apiCall({url: `/api/stock?page=${page}`, method: 'GET' });
		commit('setStocks', response)
        commit('stopLoader', response)
	},
    async filterStocks({commit, state},resp){
		commit('setStocks', resp)
	},
	async startStockLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStockLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setStocks: (state, stocks) => {
		state.stocks = stocks.data
		state.stockPagination.current_page = stocks.current_page
		state.stockPagination.total = stocks.total
		state.stockPagination.per_page = stocks.per_page
	},
	setAllStocks: (state, stocks) => {
		state.allStocks = stocks
	},
    startLoader: (state) => state.stockLoader = true,
	stopLoader: (state) => state.stockLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
