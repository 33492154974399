import apiCall from '@/utils/api'

const state = {
	categories: [],
	allCategories: [],
    categoryPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    categoryLoader: false
};

const getters = {
	categories: (state) => state.categories,
	allCategories: (state) => state.allCategories,
    categoryLoader: (state) => state.categoryLoader,
    categoryPagination: (state) => state.categoryPagination
}

const actions = {
	async fetchAllCategories({commit}, page) {
		const response = await apiCall({url: `/api/category?type=all`, method: 'GET' });
		commit('setAllCategories', response)
        commit('stopLoader', response)
	},
    async fetchCategories({commit}, page) {
		const response = await apiCall({url: `/api/category?page=${page}`, method: 'GET' });
		commit('setCategories', response)
        commit('stopLoader', response)
	},
    async filterCategories({commit, state},resp){
		commit('setCategories', resp)
	},
	async startCategoryLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopCategoryLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCategories: (state, categories) => {
		state.categories = categories.data
		state.categoryPagination.current_page = categories.current_page
		state.categoryPagination.total = categories.total
		state.categoryPagination.per_page = categories.per_page
	},
	setAllCategories: (state, categories) => {
		state.allCategories = categories
	},
    startLoader: (state) => state.categoryLoader = true,
	stopLoader: (state) => state.categoryLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
