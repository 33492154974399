import apiCall from '@/utils/api'

const state = {
	wishlist: {},
	wishlists: [],
    wishlistPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    wishlistLoader: false
};

const getters = {
    wishlistLoader: (state) => state.wishlistLoader,
	wishlists: (state) => state.wishlists,
	wishlist: (state) => state.wishlist,
    wishlistPagination: (state) => state.wishlistPagination
}

const actions = {
    async fetchWishlists({commit}, page) {
		const response = await apiCall({url: `/api/wishlist?page=${page}`, method: 'GET' });
		commit('setWishlists', response)
        commit('stopLoader', response)
	},
    async fetchWishlist({commit}, itemId) {
		const response = await apiCall({url: `/api/wishlist/${itemId}`, method: 'GET' });
		commit('setwishlist', response)
        commit('stopLoader', response)
	},
    async filterWishlists({commit, state},resp){
		commit('setWishlists', resp)
	},
	async startWishlistLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopWishlistLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setWishlists: (state, wishlists) => {
		state.wishlists = wishlists.data
		state.wishlistPagination.current_page = wishlists.current_page
		state.wishlistPagination.total = wishlists.total
		state.wishlistPagination.per_page = wishlists.per_page
	},
	setwishlist: (state, wishlist) => {
		state.wishlist = wishlist
	},
    startLoader: (state) => state.wishlistLoader = true,
	stopLoader: (state) => state.wishlistLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
