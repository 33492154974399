import apiCall from '@/utils/api'

const state = {
	carts: [],
	allCarts: [],
    cartPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    cartLoader: false
};

const getters = {
	carts: (state) => state.carts,
	allCarts: (state) => state.allCarts,
    cartLoader: (state) => state.cartLoader,
    cartPagination: (state) => state.cartPagination
}

const actions = {
	async fetchAllCarts({commit}, page) {
		const response = await apiCall({url: `/api/cart?type=all`, method: 'GET' });
		commit('setAllCarts', response)
        commit('stopLoader', response)
	},
    async fetchCarts({commit}, page) {
		const response = await apiCall({url: `/api/cart?page=${page}`, method: 'GET' });
		commit('setCarts', response)
        commit('stopLoader', response)
	},
    async filterCarts({commit, state},resp){
		commit('setCarts', resp)
	},
	async startCartLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopCartLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCarts: (state, carts) => {
		state.carts = carts.data
		state.cartPagination.current_page = carts.current_page
		state.cartPagination.total = carts.total
		state.cartPagination.per_page = carts.per_page
	},
	setAllCarts: (state, carts) => {
		state.allCarts = carts
	},
    startLoader: (state) => state.cartLoader = true,
	stopLoader: (state) => state.cartLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
