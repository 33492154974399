<template>
    <div class="learningAreas" v-if="$can('learning_area_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
    <div class="pa-5">
        <v-layout column>
          <div v-if="view == 'default'">
            <v-card elevation="0" class="mt-5">
              
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md6> 
                        <div v-if="length != 0" align="left" class="mt-1">
                            <b class="display-1 primary--text">Total: </b
                            >{{ learningAreaPagination.total | formatNumber }}
                        </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          rounded
                          label="Search"
                          append-icon="mdi-undo-variant"
                          @click:append="resetSearch()"
                          v-on:keyup.enter="search"
                          v-model="searchTerm"
                          @input="enableSearch()"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1" align="right">
                        <v-btn
                          class="button mx-1"
                          icon
                          @click="search"
                          :loading="searchLoader"
                          :disabled="searchButtonDisabled"
                        >
                          <v-icon class="secondary--text">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                          class="button mx-1"
                          @click="changeView('create')"
                          v-if="$can('learning_area_create')"
                        >
                        
                          <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-progress-linear
                v-if="learningAreaLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-flex xs12 class="mt-5">
                <div v-if="learningAreas.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="primary--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              No LearningArea Found
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="accent">
                              <th class="text-left text--text">Name</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Date Created</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in learningAreas" :key="item.id">
                              
                              <td>{{ item.name }}</td>                             
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <!-- <v-btn
                                    icon
                                    v-if="$can('learning_area_view')"
                                    @click="showLearningArea(item)"
                                    class="button mr-1 universal--text"
                                  >
                                    <v-icon small> mdi-eye </v-icon>
                                  </v-btn> -->
                                  <v-btn
                                    v-if="$can('learning_area_edit')"
                                    icon
                                    class="button mr-1 success--text"
                                    @click="editLearningArea(item)"
                                  >
                                    <v-icon small> mdi-pencil </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('learning_area_archive')"
                                    icon
                                    class="button mr-1 error--text"
                                    @click="deleteLearningArea(item)"
                                    :loading="
                                      loading && learningAreIndex == item.id
                                    "
                                  >
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('learning_area_activate') &&
                                      item.status == 0
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 red--text"
                                        @click="activateLearningArea(item)"
                                        :loading="
                                          activateLoading &&
                                          learningAreIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate LearningArea</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('learning_area_deactivate') &&
                                      item.status == 1
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 green--text"
                                        @click="deactivateLearningArea(item)"
                                        :loading="
                                          deactivateLoading &&
                                          learningAreIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate LearningArea</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <div>
                      <v-layout column>
                        <template v-for="(learningAre, index) in learningAreas">
                          <div :key="index">
                            <v-flex xs12 class="mb-2">
                              <v-card elevation="0" style="border-radius: 25px;" outlined>
                                <div class="pa-7">
                                  <v-layout column>
                                    <v-flex xs12>
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <div class="title primary--text">
                                            <b>{{ learningAre.name }}</b>
                                          </div>
                                        </v-flex>
                                        <v-flex xs1>
                                          <v-btn
                                            depressed
                                            class="red--text"
                                            :loading="
                                              loading &&
                                              learningAreIndex == learningAre.id
                                            "
                                            icon
                                            @click="
                                              deleteLearningArea(learningAre)
                                            "
                                            v-if="$can('learning_area_archive')"
                                          >
                                            <v-icon> mdi-delete </v-icon>
                                          </v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-divider class="mt-2"></v-divider>
                                    <v-flex xs12 class="mt-1">
                                      <v-layout column>
                                       
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Status</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              <div v-if="learningAre.status == 1">Active</div>
                                              <div v-else>Inactive</div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Date Created</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{
                                                learningAre.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12 v-if="$can('learning_area_view')">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="primary text-none white--text mt-3"
                                                  block
                                                  @click="
                                                    showLearningArea(learningAre)
                                                  "
                                                  rounded
                                                  >View
                                                  <v-icon right> mdi-eye </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('learning_area_edit')">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="blue text-none white--text mt-1"
                                                  block
                                                  @click="
                                                    editLearningArea(learningAre)
                                                  "
                                                  rounded
                                                  >Edit
                                                  <v-icon right>
                                                    mdi-border-color
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('learning_area_activate') &&
                                                    learningAre.status == 0">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="green text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    activateLoading &&
                                                    learningAreIndex == learningAre.id
                                                  "
                                                  @click="
                                                    activateLearningArea(learningAre)
                                                  "
                                                  rounded
                                                  >Activate LearningArea
                                                  <v-icon right>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('learning_area_deactivate') &&
                                                    learningAre.status == 1">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="red text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    deactivateLoading &&
                                                    learningAreIndex == learningAre.id
                                                  "
                                                  @click="
                                                    deactivateLearningArea(learningAre)
                                                  "
                                                  rounded
                                                  >Deactivate LearningArea
                                                  <v-icon right>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('log_view')" >
                                              <div class="mt-1">
                                                <v-btn
                                                rounded
                                                  block
                                                  depressed
                                                  class="purple text-none white--text"
                                                  @click="viewLog(learningAre)"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > Log
                                                  <v-icon small> mdi-script-text </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="left">
                  <v-pagination
                    v-if="length != 0"
                    :length="length"
                    total-visible="10"
                    v-model="learningAreaPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              
            </v-layout>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-5">
              <v-card outlined>
                <v-card-title class="accent text--text">
                  <v-img
                    v-if="newLearningArea.id != null"
                    src="img/badge.png"
                    max-width="40"
                  ></v-img>
                  <v-spacer></v-spacer>
                  <div v-if="newLearningArea.id == null">Add LearningArea</div>
                  <div v-else>Edit LearningArea</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon class="text--text"> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Name<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newLearningArea.name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                  
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    class="primary text-none mt-3"
                    @click="save"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Save
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card outlined class="mt-5">
              <v-progress-linear
                v-if="subLearningAreaLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-card-title class="accent text--text">
                <v-spacer></v-spacer>
                <div>View LearningArea</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="pa-1">
                  <v-tabs show-arrows v-model="learningAreTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
  
                    <v-tab @click="activeTab = 'details'">
                      <div class="text-none primary--text">Details</div>
                    </v-tab>
                    <!-- <v-tab
                      @click="activeTab = 'subLearningAreas'"
                    >
                      <div class="text-none primary--text">
                        Sub LearningAreas ({{
                          subLearningAreaPagination.total
                        }})
                      </div>
                    </v-tab> -->
                  </v-tabs>
                  <div v-if="activeTab == 'details'">
                    <v-card-text>
                      <div class="pa-3">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-layout column class="mt-3">
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>LearningArea Name: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedLearningArea.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Status: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div
                                              v-if="
                                                assignedLearningArea.status == 1
                                              "
                                            >
                                              Active
                                            </div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Date Created: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{
                                                assignedLearningArea.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                  </div>
  
                  <div v-if="activeTab == 'subLearningAreas'">
                    <div v-if="subLearningAreaView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <div
                                    v-if="subLearningAreaLength != 0"
                                    align="left"
                                    class="mt-1"
                                >
                                    <b class="display-1 primary--text">Total: </b
                                    >{{
                                    subLearningAreaPagination.total
                                        | formatNumber
                                    }}
                                </div>
                              </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field background"
                                    outlined
                                    dense
                                    rounded
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetSubLearningAreaSearch()
                                    "
                                    v-on:keyup.enter="
                                      subLearningAreaSearch
                                    "
                                    v-model="subLearningAreaSearchTerm"
                                    @input="
                                      enableSubLearningAreaSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1" align="right">
                                  <v-btn
                                    class="button mx-1"
                                    icon
                                    @click="subLearningAreaSearch"
                                    :loading="
                                      subLearningAreaSearchLoader
                                    "
                                    :disabled="
                                      subLearningAreaSearchButtonDisabled
                                    "
                                  >
                                    
                                    <v-icon class="secondary--text">mdi-magnify</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    class="button mx-1"
                                    @click="
                                      changeSubLearningAreaView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('sub_learning_area_create')
                                    "
                                  >
                                    <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="subLearningAreas.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Sub LearningArea Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="accent">
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">Date Created</th>
                                        <th class="text-left text--text">Status</th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in subLearningAreas"
                                        :key="item.id"
                                      >
                                        <td>
                                          {{ item.name }}
                                        </td>
                                        <td>
                                            {{
                                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                            }}
                                        </td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showSubLearningArea(
                                                  item
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-if="$can('sub_learning_area_edit')"
                                                icon
                                                class="button mr-1 success--text"
                                                @click="editSubLearningArea(item)"
                                            >
                                                <v-icon small> mdi-pencil </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'sub_learning_area_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteSubLearningArea(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                subLearningAreandex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small> mdi-delete </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'sub_learning_area_activate'
                                                ) && item.status == 0
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateSubLearningArea(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    subLearningAreandex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Activate Part</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'sub_learning_area_deactivate'
                                                ) && item.status == 1
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateSubLearningArea(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    subLearningAreandex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Deactivate Sub LearningArea</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      part, index
                                    ) in subLearningAreas"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b
                                                        >{{
                                                          part.name
                                                        }}</b
                                                      >
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        subLearningAreandex ==
                                                          part.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteSubLearningArea(
                                                          part
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'sub_learning_area_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider class="mt-2"></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            part.status ==
                                                            1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>Inactive</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Date Created</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          part.created_at | moment("DD/MM/YYYY - hh:mm a")
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
  
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showSubLearningArea(
                                                                part
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              subLearningAreandex ==
                                                                part.id
                                                            "
                                                            @click="
                                                              activateSubLearningArea(
                                                                part
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'sub_learning_area_activate'
                                                              ) && part.status == 0
                                                            "
                                                            >Activate Part
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              deactivateLoading &&
                                                              subLearningAreandex ==
                                                                part.id
                                                            "
                                                            @click="
                                                              deactivateSubLearningArea(
                                                                part
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'sub_learning_area_deactivate'
                                                              ) && part.status == 1
                                                            "
                                                            >Deactivate Sub LearningArea
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="subLearningAreaLength"
                              total-visible="10"
                              v-model="
                                subLearningAreaPagination.current_page
                              "
                              @input="changeSubLearningAreaPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="subLearningAreaView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="accent text--text">
                          <v-spacer></v-spacer>
                          <div v-if="newSubLearningArea.id == null">
                            Add Sub-LearningArea
                          </div>
                          <div v-else>Edit Sub-LearningArea</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeSubLearningAreaView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="subLearningAreaForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Sub-LearningArea Name<span class="error--text">*</span>
                                  </div>
                                  <v-text-field
                                        class="text_field background my-2"
                                        outlined
                                        dense
                                        v-model="newSubLearningArea.name"
                                        :rules="inputRules"
                                    ></v-text-field>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveSubLearningArea"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-layout>
      </div>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import { mapGetters, mapState, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  Vue.use(CKEditor);
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
  
        editor: ClassicEditor,
        editorConfig: {},
  
        importDialog: false,
        activeTab: "details",
        
        view: "default",
        subLearningAreaView: "default",
  
        contactView: "default",
        loading: false,
        subLearningAreaLoader: false,
  
        activateLoading: false,
        deactivateLoading: false,
  
        valid: true,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        tab: null,
        
        learningAreTab: null,
        subLearningAreandex: null,
        learningAreIndex: null,
  
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        emailRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        websiteRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
            "URL must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
  
        items: [],
        searchParts: null,
  
        searchTerm: null,
        subLearningAreaSearchTerm: null,
  
        searchLoader: false,
        searchButtonDisabled: false,
        subLearningAreaSearchButtonDisabled: false,
  
  
        filteredLearningAreas: false,
        filteredLearningAreaParts: false,
  
        years: [],
        newLearningArea: {
          id: null,
          name: null,
        },  
        newSubLearningArea: {
          id: null,
          learning_area_id: null,
          name: null,
        },
  
        assignedLearningArea: null,
        formData: null,
  
        coverImageDialog: false,
        imageDialog: false,
        imageLoading: false,
        fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
        imageFile: null,
        imageValid: true,
  
        searchTerm: null,
        searchLoader: false,
        subLearningAreaSearchLoader: false,
  
        searchButtonDisabled: false,
  
        filteredLearningAreas: [],
        subLearningAreas: [],
  
        subLearningAreaPagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },

        
        logData: {
          message: null,
          model: null,
        },
      };
    },
    watch: {
      searchParts(val) {
        val && val !== this.newSubLearningArea.user_id && this.findPart(val);
      },
    },
    created() {
      if (this.$can("learning_area_view")) {
        this.startLearningAreaLoader();
        this.fetchLearningAreas(this.learningAreaPagination.current_page);
        // this.getFormData();
        // this.populateYears();
      }
    },
    methods: {
      ...mapActions([
        "fetchLearningAreas",
        "startLearningAreaLoader",
        "stopLearningAreaLoader",
        "filterLearningAreas",
  
        "filterLogData",
        "openLogDataModel",
      ]),
      populateYears() {
        var max = new Date().getFullYear();
        var min = max - 100;
        var years = [];
  
        for (var i = max; i >= min; i--) {
          years.push(i);
        }
        this.years = years;
      },
      findPart(v) {
        this.loading = true;
        
        setTimeout(() => {
          apiCall({
            url: "/api/user?type=search&search=" + this.searchParts,
            method: "GET",
          })
            .then((resp) => {
              this.items = resp.data;
            })
            .catch((error) => {
              this.loading = false;
            });
  
          this.loading = false;
        }, 3000);
      },
  
      showSubLearningArea(user) {
        this.$router.push("/user/" + user.code);
      },
     
      enableSearch() {
        this.searchButtonDisabled = false;
      },
      
  
      enableSubLearningAreaSearch() {
        this.subLearningAreaSearchButtonDisabled = false;
      },
      
     
      resetSearch() {
        this.searchTerm = null;
        this.filteredLearningAreas = true;
        this.startLearningAreaLoader();
        this.fetchLearningAreas(1);
      },    
  
      resetSubLearningAreaSearch() {
        this.subLearningAreaSearchTerm = null;
        this.filteredLearningAreaParts = false;
        this.subLearningAreaLoader = false;
        this.changeSubLearningAreaPage();
      },

      changeSubLearningAreaPage() {
        this.subLearningAreaLoader = true;
        if (this.filteredLearningAreaParts == true) {
          this.subLearningAreaSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/sub-learningAre?learning_area_id=" +
              this.assignedLearningArea.id +
              "&page=" +
              this.subLearningAreaPagination.current_page +
              "&type=search&search=" +
              this.subLearningAreaSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.subLearningAreaLoader = false;
  
              this.subLearningAreas = resp.data;
              this.subLearningAreaPagination.current_page =
                resp.current_page;
              this.subLearningAreaPagination.total = resp.total;
              this.subLearningAreaPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.subLearningAreaLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          apiCall({
            url:
              "/api/sub-learningAre?learning_area_id=" +
              this.assignedLearningArea.id +
              "&page=" +
              this.subLearningAreaPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.subLearningAreaLoader = false;
  
              this.subLearningAreas = resp.data;
              this.subLearningAreaPagination.current_page =
                resp.current_page;
              this.subLearningAreaPagination.total = resp.total;
              this.subLearningAreaPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.subLearningAreaLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
      getText(item) {
        return (
          item.first_name + " " + item.middle_name + " " + item.last_name + " #" + item.code
        );
      },
      showLearningArea(learningAre) {
        this.assignedLearningArea = learningAre;

        // this.changeSubLearningAreaPage();
        this.changeView("show");
      },
  
      editLearningArea(learningAre) {
        this.newLearningArea.id = learningAre.id;
        this.newLearningArea.name = learningAre.name;
  
        console.log("this.newLearningArea", this.newLearningArea)
  
        this.changeView("create");
      },

      editSubLearningArea(subLearningArea) {
        this.newSubLearningArea.id = subLearningArea.id;
        this.newSubLearningArea.name = subLearningArea.name;
  
        console.log("this.newSubLearningArea", this.newSubLearningArea)
  
        this.changeSubLearningAreaView("create");
      },
  
      save() {
        if (this.$refs.form.validate()) {
          if (this.newLearningArea.id != null) {
            confirm("Are you sure you want to update learningAre?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/learning-area/" + this.newLearningArea.id,
                data: this.newLearningArea,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "LearningArea Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchLearningAreas(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create learningAre?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/learning-area",
                data: this.newLearningArea,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "LearningArea Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchLearningAreas(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchLearningAreas(1);
                });
            }
          }
        }
      },
  
      saveSubLearningArea() {
        if (this.$refs.subLearningAreaForm.validate()) {
            if (this.newSubLearningArea.id != null) {
            confirm("Are you sure you want to update sub learningAre?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/sub-learningAre/" + this.newSubLearningArea.id,
                data: this.newSubLearningArea,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Sub-LearningArea Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeSubLearningAreaView("default");
                  this.changeSubLearningAreaPage(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {


          confirm("Are you sure you want to add Sub-LearningArea?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newSubLearningArea.learning_area_id = this.assignedLearningArea.id;
            apiCall({
              url: "/api/sub-learningAre",
              data: this.newSubLearningArea,
              method: "POST",
            })
              .then((resp) => {
                this.message = "LearningArea Part Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.newSubLearningArea.user_id = null;
                this.changeSubLearningAreaView("default");
                this.changeSubLearningAreaPage();
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
        }
      },
  
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startLearningAreaLoader();
          apiCall({
            url: "/api/learning-area?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterLearningAreas(resp);
              this.searchLoader = false;
              this.stopLearningAreaLoader();
              this.filteredLearningAreas = true;
            })
            .catch((error) => {
              console.log(error);
              this.searchLoader = false;
              this.stopLearningAreaLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
  
      subLearningAreaSearch() {
        if (this.subLearningAreaSearchTerm == null) {
          this.subLearningAreaSearchButtonDisabled = true;
        } else {
          this.subLearningAreaSearchLoader = true;
          this.subLearningAreaSearchButtonDisabled = false;
          this.startLearningAreaLoader();
          apiCall({
            url:
              "/api/sub-learningAre?type=search&search=" +
              this.subLearningAreaSearchTerm +
              "&learning_area_id=" +
              this.assignedLearningArea.id,
            method: "GET",
          })
            .then((resp) => {
              this.subLearningAreas = resp.data;
              this.subLearningAreaPagination.current_page =
                resp.current_page;
              this.subLearningAreaPagination.total = resp.total;
              this.subLearningAreaPagination.per_page = resp.per_page;
  
              this.subLearningAreaSearchLoader = false;
              this.filteredLearningAreaParts = true;
            })
            .catch((error) => {
              this.subLearningAreaSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      activateLearningArea(item) {
        confirm("Are You Sure You Want to Activate LearningArea?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.learningAreIndex = item.id;
          this.newLearningArea.id = item.id;
          apiCall({
            url: "/api/learning-area?type=activate",
            data: this.newLearningArea,
            method: "POST",
          })
            .then((resp) => {
              this.message = "LearningArea Account Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.learningAreIndex = null;
              this.newLearningArea.id = null;
              this.fetchLearningAreas(this.learningAreaPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.learningAreaIndex = null;
              this.newLearningArea.id = null;
              console.log(error);
            });
        }
      },
      deactivateLearningArea(item) {
        confirm("Are You Sure You Want to Deactivate LearningArea?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.learningAreIndex = item.id;
          this.newLearningArea.id = item.id;
          apiCall({
            url: "/api/learning-area?type=deactivate",
            data: this.newLearningArea,
            method: "POST",
          })
            .then((resp) => {
              this.message = "LearningArea Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.learningAreIndex = null;
              this.newLearningArea.id = null;
              this.fetchLearningAreas(this.learningAreaPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.learningAreIndex = null;
              this.newLearningArea.id = null;
              console.log(error);
            });
        }
      },
     
      activateSubLearningArea(item) {
        confirm(
          "Are You Sure You Want to Activate LearningArea Part?"
        ) && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.subLearningAreandex = item.id;
          this.newSubLearningArea.id = item.id;
          apiCall({
            url: "/api/sub-learningAre?type=activate",
            data: this.newSubLearningArea,
            method: "POST",
          })
            .then((resp) => {
              this.message = "LearningArea Part Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.subLearningAreandex = null;
              this.newSubLearningArea.id = null;
              this.changeSubLearningAreaPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.subLearningAreandex = null;
              this.newSubLearningArea.id = null;
              console.log(error);
            });
        }
      },
  
      deactivateSubLearningArea(item) {
        confirm(
          "Are You Sure You Want to Deactivate LearningArea Part?"
        ) && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.subLearningAreandex = item.id;
          this.newSubLearningArea.id = item.id;
          apiCall({
            url: "/api/sub-learningAre?type=deactivate",
            data: this.newSubLearningArea,
            method: "POST",
          })
            .then((resp) => {
              this.message = "LearningArea Part Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.subLearningAreandex = null;
              this.newSubLearningArea.id = null;
              this.changeSubLearningAreaPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.subLearningAreandex = null;
              this.newSubLearningArea.id = null;
              console.log(error);
            });
        }
      },
      
      changeView(view) {
        if (view == "default") {
          this.newLearningArea.id = null;
          this.newLearningArea.name = null;
          this.newLearningArea.learning_area_type_id = null;
          this.newLearningArea.county_id = null;
          this.newLearningArea.brief = null;
          this.newLearningArea.physical_address = null;
        } else if (view == "create") {
        //   this.getFormData();
        } else if (view == "show") {
        }
        this.view = view;
      },     
  
      changeSubLearningAreaView(view) {
        if (view == "default") {
          this.newSubLearningArea.id = null;
          this.newSubLearningArea.learning_area_id = null;
          this.newSubLearningArea.name = null;
        } else if (view == "create") {
        } else if (view == "show") {
        }
        this.subLearningAreaView = view;
      },
      
      changePage() {
        this.startLearningAreaLoader();
        if (this.filteredLearningAreas == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/learning-area?page=" +
              this.learningAreaPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterLearningAreas(resp);
              this.stopLearningAreaLoader();
            })
            .catch((error) => {
              console.log(error);
              this.stopLearningAreaLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchLearningAreas(this.learningAreaPagination.current_page);
        }
      },
      deleteLearningArea(item) {
        confirm("Are You Sure You Want to Delete LearningArea") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.learningAreIndex = item.id;
          apiCall({ url: "/api/learning-area/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "LearningArea Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.learningAreIndex = null;
              this.fetchLearningAreas(this.learningAreaPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.learningAreIndex = null;
              console.log(error);
            });
        }
      },
  
      deleteSubLearningArea(item){
        confirm("Are You Sure You Want to Remove Sub-LearningArea?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.subLearningAreandex = item.id;
          apiCall({
            url: "/api/sub-learningAre/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Sub-LearningArea Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.subLearningAreandex = null;
              this.changeSubLearningAreaPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.subLearningAreandex = null;
              console.log(error);
            });
        }
      },
      
    //   getFormData() {
    //     apiCall({
    //       url: "/api/learning-area?type=formData",
    //       method: "GET",
    //     })
    //       .then((resp) => {
    //         this.formData = resp;
    //       })
    //       .catch((error) => {
    //         this.message = "anErrorOccurredCouldntGetFormDataMsg";
    //         this.color = "error";
    //       });
    //   },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\LearningArea";
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "learningAreas",
        "learningAreaPagination",
        "learningAreaLoader",
        "logDataModel"
      ]),
      length: function () {
        return Math.ceil(
          this.learningAreaPagination.total / this.learningAreaPagination.per_page
        );
      },
      subLearningAreaLength: function () {
        return Math.ceil(
          this.subLearningAreaPagination.total /
            this.subLearningAreaPagination.per_page
        );
      },
    
      dateToday() {
        var today = new Date();
  
        var timestamp =
          today.getFullYear() +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
    },
  };
  </script>
  