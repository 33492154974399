import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/redirector')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/roles',
    name: 'Roles',
    component: require('@/views/accessControl/role.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: require('@/views/accessControl/user.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/students',
    name: 'Students',
    component: require('@/views/admin/student.vue').default,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/logs',
  //   name: 'Log',
  //   component: require('@/views/accessControl/log.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/reports',
  //   name: 'Report',
  //   component: require('@/views/accessControl/report.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },

  {
    path: '/categories-admin',
    name: 'categoryAdmin',
    component: require('@/views/admin/categoryAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/levels-admin',
    name: 'levelAdmin',
    component: require('@/views/admin/levelAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/learning-areas-admin',
    name: 'learningAreaAdmin',
    component: require('@/views/admin/learningAreaAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/manufacturers-admin',
    name: 'manufacturerAdmin',
    component: require('@/views/admin/manufacturerAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/suppliers-admin',
    name: 'supplierAdmin',
    component: require('@/views/admin/supplierAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/parts-admin',
    name: 'partAdmin',
    component: require('@/views/admin/partAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/sales-admin',
    name: 'saleAdmin',
    component: require('@/views/admin/saleAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/refunds-admin',
    name: 'refundAdmin',
    component: require('@/views/admin/refundAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ads-admin',
    name: 'adAdmin',
    component: require('@/views/admin/adAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/parts',
    name: 'reportPart',
    component: require('@/views/reports/part.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/stock',
    name: 'reportStock',
    component: require('@/views/reports/stock.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/purchase',
    name: 'reportPurchase',
    component: require('@/views/reports/purchase.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/sales',
    name: 'reportSale',
    component: require('@/views/reports/sale.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/sale-items',
    name: 'reportSaleItem',
    component: require('@/views/reports/saleItem.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/mpesa',
    name: 'reportMpesa',
    component: require('@/views/reports/mpesa.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/cash',
    name: 'reportCash',
    component: require('@/views/reports/cash.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/daily-stock',
    name: 'reportDailyStock',
    component: require('@/views/reports/dailyStock.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/wallet',
    name: 'reportWallet',
    component: require('@/views/reports/wallet.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/wallet-logs',
    name: 'reportWalletLog',
    component: require('@/views/reports/walletLog.vue').default,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/contact-us-admin',
  //   name: 'contactUsAdmin',
  //   component: require('@/views/admin/contactUsAdmin.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },


  {
    path: '/profile',
    name: 'Profile',
    component: require('@/views/Profile.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: require('@/views/Wallet.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/sales',
    name: 'Sale',
    component: require('@/views/Sale.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: require('@/views/Notification.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: require("@/views/Dashboard.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/dashboard',
    name: 'AdminDashboard',
    component: require("@/views/admin/dashboard.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/redirector",
    name: "Redirector",
    component: require("@/views/Redirector.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/home",
    name: "Home",
    component: require("@/views/Home.vue").default,
    beforeEnter: ifAuthenticated,
  },

  // {
  //   path: "/events",
  //   name: "Events",
  //   component: require("@/views/Events.vue").default,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: "/events/:slug",
  //   name: "Event",
  //   component: require("@/views/Event.vue").default,
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/search",
    name: "Search",
    component: require("@/views/Search.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: require("@/views/Wishlist.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/cart",
    name: "Cart",
    component: require("@/views/Cart.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: require("@/views/Checkout.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/categories/:category/:subcategory',
    name: 'SubCategory',
    component: require("@/views/SubCategory.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/levels/:level/:sublevel',
    name: 'SubLevel',
    component: require("@/views/SubLevel.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/learning-areas/:learningArea',
    name: 'learningArea',
    component: require("@/views/LearningArea.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/product/:product',
    name: 'Product',
    component: require("@/views/Product.vue").default,
    beforeEnter: ifAuthenticated,
  },


  {
    path: '/reset-password/:token',
    name: 'ResetPass',
    component: require("@/components/auth/resetPass.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: require("@/views/PrivacyPolicy.vue").default,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: require("@/views/TermsOfUse.vue").default,
  },
  { path: "*", 
    name: 'PageNotFound',
    component: require("@/views/PageNotFound.vue").default,
  }
]

const router = new VueRouter({
  routes
})

export default router
