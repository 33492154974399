<template>
    <div class="ads" v-if="$can('ad_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="imageDialog"
        >
          <v-card max-width="600">
            <v-toolbar flat>
              Upload Logo
              <v-spacer></v-spacer>
              <v-btn icon @click="imageDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
    
            <v-form ref="imageForm" v-model="imageValid" lazy-validation>
              <v-layout column>
                <v-flex xs12>
                  <v-file-input
                    class="mx-2 mt-3"
                    :loading="imageLoading"
                    :rules="fileRules"
                    filled
                    dense
                    show-size
                    chips
                    accept="image/*"
                    v-model="imageFile"
                    label="Select Image"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12>
                  <div class="mx-3 mb-5">
                    <v-btn
                      depressed
                      block
                      class="primary text-none"
                      @click="uploadImage"
                      :loading="imageLoading"
                      :disabled="!imageValid"
                    >
                      Submit <v-icon right dark>mdi-upload</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-dialog>
    <div class="pa-5">
        <v-layout column>
          <div v-if="view == 'default'">
            <v-card elevation="0" class="mt-5">
              
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md6> 
                        <div v-if="length != 0" align="left" class="mt-1">
                            <b class="display-1 primary--text">Total: </b
                            >{{ adPagination.total | formatNumber }}
                        </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          rounded
                          label="Search"
                          append-icon="mdi-undo-variant"
                          @click:append="resetSearch()"
                          v-on:keyup.enter="search"
                          v-model="searchTerm"
                          @input="enableSearch()"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1" align="right">
                        <v-btn
                          class="button mx-1"
                          icon
                          @click="search"
                          :loading="searchLoader"
                          :disabled="searchButtonDisabled"
                        >
                          <v-icon class="secondary--text">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                          class="button mx-1"
                          @click="changeView('create')"
                          v-if="$can('ad_create')"
                        >
                        
                          <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-progress-linear
                v-if="adLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-flex xs12 class="mt-5">
                <div v-if="ads.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="primary--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              No Ad Found
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="accent">
                              <th class="text-left text--text">Image</th>
                              <th class="text-left text--text">Title</th>
                              <th class="text-left text--text">Tagline</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Date Created</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in ads" :key="item.id">
                              <td>
                                <div class="my-2">
                                  <v-avatar size="48" v-if="item.image==null">
                                    <v-img
                                      max-height="150"
                                      
                                      src="img/logo.gif"
                                    >
                                    </v-img>
                                  </v-avatar>
                                  <v-avatar size="48" v-else>
                                    <v-img
                                        max-height="150"
                                        :src="path+'/storage/ad_pictures/'+item.image"
                                      >
                                      </v-img>
                                  </v-avatar>
                                </div>
                              </td>
                              <td>{{ item.title }}</td>
                              <td>{{ item.Tagline }}</td>                             
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-btn
                                    icon
                                    v-if="$can('ad_view')"
                                    @click="showAd(item)"
                                    class="button mr-1 universal--text"
                                  >
                                    <v-icon small> mdi-eye </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('ad_edit')"
                                    icon
                                    class="button mr-1 success--text"
                                    @click="editAd(item)"
                                  >
                                    <v-icon small> mdi-pencil </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('ad_archive')"
                                    icon
                                    class="button mr-1 error--text"
                                    @click="deleteAd(item)"
                                    :loading="
                                      loading && adIndex == item.id
                                    "
                                  >
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('ad_activate') &&
                                      item.status == 0
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 red--text"
                                        @click="activateAd(item)"
                                        :loading="
                                          activateLoading &&
                                          adIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Ad</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('ad_deactivate') &&
                                      item.status == 1
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 green--text"
                                        @click="deactivateAd(item)"
                                        :loading="
                                          deactivateLoading &&
                                          adIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Ad</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <div>
                      <v-layout column>
                        <template v-for="(ad, index) in ads">
                          <div :key="index">
                            <v-flex xs12 class="mb-2">
                              <v-card elevation="0" style="border-radius: 25px;" outlined>
                                <div class="pa-5">
                                  <v-layout column>
                                    <v-flex xs12>
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <div class="title primary--text">
                                            <b>{{ ad.title }}</b>
                                          </div>
                                        </v-flex>
                                        <v-flex xs1>
                                          <v-btn
                                            depressed
                                            class="red--text"
                                            :loading="
                                              loading &&
                                              adIndex == ad.id
                                            "
                                            icon
                                            @click="
                                              deleteAd(ad)
                                            "
                                            v-if="$can('ad_archive')"
                                          >
                                            <v-icon> mdi-delete </v-icon>
                                          </v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-divider class="mt-2"></v-divider>
                                    <v-flex xs12 class="mt-1">
                                      <v-layout column>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Tagline</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{ ad.Tagline }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Status</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              <div v-if="ad.status == 1">Active</div>
                                              <div v-else>Inactive</div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Date Created</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{
                                                ad.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12  v-if="$can('ad_view')">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="primary text-none white--text mt-3"
                                                  block
                                                  @click="
                                                    showAd(ad)
                                                  "
                                                 rounded
                                                  >View
                                                  <v-icon right> mdi-eye </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('ad_edit')">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="blue text-none white--text mt-1"
                                                  block
                                                  @click="
                                                    editAd(ad)
                                                  "
                                                  rounded
                                                  >Edit
                                                  <v-icon right>
                                                    mdi-border-color
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('ad_activate') &&
                                                    ad.status == 0">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="green text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    activateLoading &&
                                                    adIndex == ad.id
                                                  "
                                                  @click="
                                                    activateAd(ad)
                                                  "
                                                  rounded
                                                  >Activate Ad
                                                  <v-icon right>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('ad_deactivate') &&
                                                    ad.status == 1">
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="red text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    deactivateLoading &&
                                                    adIndex == ad.id
                                                  "
                                                  @click="
                                                    deactivateAd(ad)
                                                  "
                                                  rounded
                                                  >Deactivate Ad
                                                  <v-icon right>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('log_view')" >
                                              <div class="mt-1">
                                                <v-btn
                                                  rounded
                                                  block
                                                  depressed
                                                  class="purple text-none white--text"
                                                  @click="viewLog(ad)"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > Log
                                                  <v-icon small> mdi-script-text </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="left">
                  <v-pagination
                    v-if="length != 0"
                    :length="length"
                    total-visible="10"
                    v-model="adPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              
            </v-layout>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-5">
              <v-card outlined>
                <v-card-title class="accent text--text">
                  <v-spacer></v-spacer>
                  <div v-if="newAd.id == null">Add Ad</div>
                  <div v-else>Edit Ad</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon class="text--text"> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Title<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newAd.title"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
  
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Tagline</div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newAd.tagline"
                          ></v-text-field>
                        </div>
                      </v-flex>

                      <v-flex xs10 md2>
                        <div class="pa-3">
                          <div>Background Colour<span class="error--text">*</span></div>
                          <v-select
                            :items="colors"
                            v-model="newAd.background_color"
                            class="text_field background my-2"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                          ></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs2 md1>
                        <div align="center">
                            <v-icon large :color="newAd.background_color" class="mt-11">
                                mdi-circle
                            </v-icon>
                        </div>
                      </v-flex>

                      <v-flex xs10 md2>
                        <div class="pa-3">
                          <div>Text Colour<span class="error--text">*</span></div>
                          <v-select
                            :items="colors"
                            v-model="newAd.text_color"
                            class="text_field background my-2"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                          ></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs2 md1>
                        <div align="center">
                            <v-icon large :color="newAd.text_color" class="mt-11">
                                mdi-circle
                            </v-icon>
                        </div>
                      </v-flex>
                  
                     
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Description</div>
                          <div class="black--text">
                            <ckeditor
                              :editor="editor"
                              v-model="newAd.description"
                              :config="editorConfig"
                              :rules="inputRules"
                            ></ckeditor>
                          </div>                          
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    class="primary text-none mt-3"
                    @click="save"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Save
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card outlined class="mt-5">
              <v-progress-linear
                v-if="adPartLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-card-title class="accent text--text">
                <v-spacer></v-spacer>
                <div>View Ad</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="pa-1">
                  <v-tabs show-arrows v-model="adTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
  
                    <v-tab @click="activeTab = 'details'">
                      <div class="text-none primary--text">Details</div>
                    </v-tab>
                    
                  </v-tabs>
                  <div v-if="activeTab == 'details'">
                    <v-card-text>
                      <div class="pa-3">
                        <v-layout row wrap>
                          <v-flex xs12 md2>
                            <div align="center" class="mx-4">                            
                                <v-img
                                v-if="assignedAd.image==null"
                                src="img/logo.gif"
                                contain
                                >
                                </v-img>
                                <v-img
                                v-else
                                :src="path+'/storage/ad_pictures/'+assignedAd.image"
                                contain
                                style="border-radius: 25px;"
                                >
                                </v-img>                              
                            </div>
                            <div align="center" class="mt-5">
                              <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('ad_edit')">
                                Upload Logo
                                <v-icon right>mdi-file-upload</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex xs12 md10>
                            <div class="mx-8">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Title: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedAd.title }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Background Color: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedAd.background_color }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Text Color: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedAd.text_color }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Status: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div
                                              v-if="
                                                assignedAd.status == 1
                                              "
                                            >
                                              Active
                                            </div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Date Created: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{
                                                assignedAd.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md2>
                                        <b>Description: </b>
                                        </v-flex>
                                        <v-flex xs12 md10>
                                        <div v-html="assignedAd.description" class="ml-1">
                                          
                                        </div>
                                        </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="mt-15 mx-6">
                        <v-divider></v-divider>
                      </div>
                      <div class="text-h4 mt-14 mx-3">
                        Preview
                      </div>
                      <div class="mt-5 pa-3">
                        <v-sheet
                            :color="assignedAd.background_color"
                            height="500"
                            style="border-radius: 25px;"
                        >
                            <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                            >
                            <v-layout row wrap>
                                <v-flex xs12 md4>
                                    <v-img contain
                                        max-height="400"
                                        class="mx-15"
                                        style="border-radius: 25px;"
                                        :src="path+'/storage/ad_pictures/'+assignedAd.image"/>
                                </v-flex>
                                <v-flex xs12 md8>
                                    <div :class="'text-h2 font-weight-black mt-15 ' +assignedAd.text_color+'--text'" align="center">
                                        {{ assignedAd.title }}
                                    </div>
                                    <div :class="'text-h6 font-weight-black ' +assignedAd.text_color+'--text'" align="center">
                                        {{ assignedAd.tagline }}
                                    </div>
                                    <div :class="'mt-5 mx-15 ' +assignedAd.text_color+'--text'" align="center" v-html="assignedAd.description">
                                        
                                    </div>
                                </v-flex>
                            </v-layout>
                            
                            </v-row>
                        </v-sheet>
                      </div>
                    </v-card-text>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-layout>
      </div>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import { mapGetters, mapState, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  Vue.use(CKEditor);
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
  
        editor: ClassicEditor,
        editorConfig: {},
  
        activeTab: "details",
        
        view: "default",
        loading: false,
  
        activateLoading: false,
        deactivateLoading: false,
  
        valid: true,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        tab: null,
        
        adTab: null,
        adIndex: null,
  
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        emailRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        websiteRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
            "URL must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
  
        items: [],
  
        searchTerm: null,
  
        searchLoader: false,
        searchButtonDisabled: false,
        filteredAds: false,
        
        newAd: {
          id: null,
          title: null,
          tagline: null,
          description: null,
          background_color: null,
          text_color: null,
        },
  
        assignedAd: null,
        formData: null,
  
        imageDialog: false,
        imageLoading: false,
        fileRules: [(v) => !!v || "Kindly Select a File"],
        imageFile: null,
        imageValid: true,
  
        searchTerm: null,
        searchLoader: false,
  
        searchButtonDisabled: false,
        
        logData: {
          message: null,
          model: null,
        },

        colors: [
            {
                name: 'primary'
            },
            {
                name: 'secondary'
            },
            {
                name: 'accent'
            },
            {
                name: 'black'
            },
            {
                name: 'white'
            },
            {
                name: 'transpareny'
            },
            {
                name: 'red'
            },
            {
                name: 'red lighten-5'
            },
            {
                name: 'red lighten-4'
            },
            {
                name: 'red lighten-3'
            },
            {
                name: 'red lighten-2'
            },
            {
                name: 'red lighten-1'
            },
            {
                name: 'red darken-1'
            },
            {
                name: 'red darken-2'
            },
            {
                name: 'red darken-3'
            },
            {
                name: 'red darken-4'
            },
            {
                name: 'red accent-1'
            },
            {
                name: 'red accent-2'
            },
            {
                name: 'red accent-3'
            },
            {
                name: 'red accent-4'
            },
            {
                name: 'pink'
            },
            {
                name: 'pink lighten-5'
            },
            {
                name: 'pink lighten-4'
            },
            {
                name: 'pink lighten-3'
            },
            {
                name: 'pink lighten-2'
            },
            {
                name: 'pink lighten-1'
            },
            {
                name: 'pink darken-1'
            },
            {
                name: 'pink darken-2'
            },
            {
                name: 'pink darken-3'
            },
            {
                name: 'pink darken-4'
            },
            {
                name: 'pink accent-1'
            },
            {
                name: 'pink accent-2'
            },
            {
                name: 'pink accent-3'
            },
            {
                name: 'pink accent-4'
            },
            {
                name: 'purple'
            },
            {
                name: 'purple lighten-5'
            },
            {
                name: 'purple lighten-4'
            },
            {
                name: 'purple lighten-3'
            },
            {
                name: 'purple lighten-2'
            },
            {
                name: 'purple lighten-1'
            },
            {
                name: 'purple darken-1'
            },
            {
                name: 'purple darken-2'
            },
            {
                name: 'purple darken-3'
            },
            {
                name: 'purple darken-4'
            },
            {
                name: 'purple accent-1'
            },
            {
                name: 'purple accent-2'
            },
            {
                name: 'purple accent-3'
            },
            {
                name: 'purple accent-4'
            },
            {
                name: 'deep-purple'
            },
            {
                name: 'deep-purple lighten-5'
            },
            {
                name: 'deep-purple lighten-4'
            },
            {
                name: 'deep-purple lighten-3'
            },
            {
                name: 'deep-purple lighten-2'
            },
            {
                name: 'deep-purple lighten-1'
            },
            {
                name: 'deep-purple darken-1'
            },
            {
                name: 'deep-purple darken-2'
            },
            {
                name: 'deep-purple darken-3'
            },
            {
                name: 'deep-purple darken-4'
            },
            {
                name: 'deep-purple accent-1'
            },
            {
                name: 'deep-purple accent-2'
            },
            {
                name: 'deep-purple accent-3'
            },
            {
                name: 'deep-purple accent-4'
            },
            {
                name: 'indigo'
            },
            {
                name: 'indigo lighten-5'
            },
            {
                name: 'indigo lighten-4'
            },
            {
                name: 'indigo lighten-3'
            },
            {
                name: 'indigo lighten-2'
            },
            {
                name: 'indigo lighten-1'
            },
            {
                name: 'indigo darken-1'
            },
            {
                name: 'indigo darken-2'
            },
            {
                name: 'indigo darken-3'
            },
            {
                name: 'indigo darken-4'
            },
            {
                name: 'indigo accent-1'
            },
            {
                name: 'indigo accent-2'
            },
            {
                name: 'indigo accent-3'
            },
            {
                name: 'indigo accent-4'
            },
            {
                name: 'blue'
            },
            {
                name: 'blue lighten-5'
            },
            {
                name: 'blue lighten-4'
            },
            {
                name: 'blue lighten-3'
            },
            {
                name: 'blue lighten-2'
            },
            {
                name: 'blue lighten-1'
            },
            {
                name: 'blue darken-1'
            },
            {
                name: 'blue darken-2'
            },
            {
                name: 'blue darken-3'
            },
            {
                name: 'blue darken-4'
            },
            {
                name: 'blue accent-1'
            },
            {
                name: 'blue accent-2'
            },
            {
                name: 'blue accent-3'
            },
            {
                name: 'blue accent-4'
            },
            {
                name: 'light-blue'
            },
            {
                name: 'light-blue lighten-5'
            },
            {
                name: 'light-blue lighten-4'
            },
            {
                name: 'light-blue lighten-3'
            },
            {
                name: 'light-blue lighten-2'
            },
            {
                name: 'light-blue lighten-1'
            },
            {
                name: 'light-blue darken-1'
            },
            {
                name: 'light-blue darken-2'
            },
            {
                name: 'light-blue darken-3'
            },
            {
                name: 'light-blue darken-4'
            },
            {
                name: 'light-blue accent-1'
            },
            {
                name: 'light-blue accent-2'
            },
            {
                name: 'light-blue accent-3'
            },
            {
                name: 'light-blue accent-4'
            },
            {
                name: 'cyan'
            },
            {
                name: 'cyan lighten-5'
            },
            {
                name: 'cyan lighten-4'
            },
            {
                name: 'cyan lighten-3'
            },
            {
                name: 'cyan lighten-2'
            },
            {
                name: 'cyan lighten-1'
            },
            {
                name: 'cyan darken-1'
            },
            {
                name: 'cyan darken-2'
            },
            {
                name: 'cyan darken-3'
            },
            {
                name: 'cyan darken-4'
            },
            {
                name: 'cyan accent-1'
            },
            {
                name: 'cyan accent-2'
            },
            {
                name: 'cyan accent-3'
            },
            {
                name: 'cyan accent-4'
            },
            {
                name: 'teal'
            },
            {
                name: 'teal lighten-5'
            },
            {
                name: 'teal lighten-4'
            },
            {
                name: 'teal lighten-3'
            },
            {
                name: 'teal lighten-2'
            },
            {
                name: 'teal lighten-1'
            },
            {
                name: 'teal darken-1'
            },
            {
                name: 'teal darken-2'
            },
            {
                name: 'teal darken-3'
            },
            {
                name: 'teal darken-4'
            },
            {
                name: 'teal accent-1'
            },
            {
                name: 'teal accent-2'
            },
            {
                name: 'teal accent-3'
            },
            {
                name: 'teal accent-4'
            },
            {
                name: 'green'
            },
            {
                name: 'green lighten-5'
            },
            {
                name: 'green lighten-4'
            },
            {
                name: 'green lighten-3'
            },
            {
                name: 'green lighten-2'
            },
            {
                name: 'green lighten-1'
            },
            {
                name: 'green darken-1'
            },
            {
                name: 'green darken-2'
            },
            {
                name: 'green darken-3'
            },
            {
                name: 'green darken-4'
            },
            {
                name: 'green accent-1'
            },
            {
                name: 'green accent-2'
            },
            {
                name: 'green accent-3'
            },
            {
                name: 'green accent-4'
            },
            {
                name: 'light-green'
            },
            {
                name: 'light-green lighten-5'
            },
            {
                name: 'light-green lighten-4'
            },
            {
                name: 'light-green lighten-3'
            },
            {
                name: 'light-green lighten-2'
            },
            {
                name: 'light-green lighten-1'
            },
            {
                name: 'light-green darken-1'
            },
            {
                name: 'light-green darken-2'
            },
            {
                name: 'light-green darken-3'
            },
            {
                name: 'light-green darken-4'
            },
            {
                name: 'light-green accent-1'
            },
            {
                name: 'light-green accent-2'
            },
            {
                name: 'light-green accent-3'
            },
            {
                name: 'light-green accent-4'
            },
            {
                name: 'lime'
            },
            {
                name: 'lime lighten-5'
            },
            {
                name: 'lime lighten-4'
            },
            {
                name: 'lime lighten-3'
            },
            {
                name: 'lime lighten-2'
            },
            {
                name: 'lime lighten-1'
            },
            {
                name: 'lime darken-1'
            },
            {
                name: 'lime darken-2'
            },
            {
                name: 'lime darken-3'
            },
            {
                name: 'lime darken-4'
            },
            {
                name: 'lime accent-1'
            },
            {
                name: 'lime accent-2'
            },
            {
                name: 'lime accent-3'
            },
            {
                name: 'lime accent-4'
            },
            {
                name: 'yellow'
            },
            {
                name: 'yellow lighten-5'
            },
            {
                name: 'yellow lighten-4'
            },
            {
                name: 'yellow lighten-3'
            },
            {
                name: 'yellow lighten-2'
            },
            {
                name: 'yellow lighten-1'
            },
            {
                name: 'yellow darken-1'
            },
            {
                name: 'yellow darken-2'
            },
            {
                name: 'yellow darken-3'
            },
            {
                name: 'yellow darken-4'
            },
            {
                name: 'yellow accent-1'
            },
            {
                name: 'yellow accent-2'
            },
            {
                name: 'yellow accent-3'
            },
            {
                name: 'yellow accent-4'
            },
            {
                name: 'amber'
            },
            {
                name: 'amber lighten-5'
            },
            {
                name: 'amber lighten-4'
            },
            {
                name: 'amber lighten-3'
            },
            {
                name: 'amber lighten-2'
            },
            {
                name: 'amber lighten-1'
            },
            {
                name: 'amber darken-1'
            },
            {
                name: 'amber darken-2'
            },
            {
                name: 'amber darken-3'
            },
            {
                name: 'amber darken-4'
            },
            {
                name: 'amber accent-1'
            },
            {
                name: 'amber accent-2'
            },
            {
                name: 'amber accent-3'
            },
            {
                name: 'amber accent-4'
            },
            {
                name: 'orange'
            },
            {
                name: 'orange lighten-5'
            },
            {
                name: 'orange lighten-4'
            },
            {
                name: 'orange lighten-3'
            },
            {
                name: 'orange lighten-2'
            },
            {
                name: 'orange lighten-1'
            },
            {
                name: 'orange darken-1'
            },
            {
                name: 'orange darken-2'
            },
            {
                name: 'orange darken-3'
            },
            {
                name: 'orange darken-4'
            },
            {
                name: 'orange accent-1'
            },
            {
                name: 'orange accent-2'
            },
            {
                name: 'orange accent-3'
            },
            {
                name: 'orange accent-4'
            },
            {
                name: 'deep-orange'
            },
            {
                name: 'deep-orange lighten-5'
            },
            {
                name: 'deep-orange lighten-4'
            },
            {
                name: 'deep-orange lighten-3'
            },
            {
                name: 'deep-orange lighten-2'
            },
            {
                name: 'deep-orange lighten-1'
            },
            {
                name: 'deep-orange darken-1'
            },
            {
                name: 'deep-orange darken-2'
            },
            {
                name: 'deep-orange darken-3'
            },
            {
                name: 'deep-orange darken-4'
            },
            {
                name: 'deep-orange accent-1'
            },
            {
                name: 'deep-orange accent-2'
            },
            {
                name: 'deep-orange accent-3'
            },
            {
                name: 'deep-orange accent-4'
            },
            {
                name: 'brown'
            },
            {
                name: 'brown lighten-5'
            },
            {
                name: 'brown lighten-4'
            },
            {
                name: 'brown lighten-3'
            },
            {
                name: 'brown lighten-2'
            },
            {
                name: 'brown lighten-1'
            },
            {
                name: 'brown darken-1'
            },
            {
                name: 'brown darken-2'
            },
            {
                name: 'brown darken-3'
            },
            {
                name: 'brown darken-4'
            },
            {
                name: 'blue-grey'
            },
            {
                name: 'blue-grey lighten-5'
            },
            {
                name: 'blue-grey lighten-4'
            },
            {
                name: 'blue-grey lighten-3'
            },
            {
                name: 'blue-grey lighten-2'
            },
            {
                name: 'blue-grey lighten-1'
            },
            {
                name: 'blue-grey darken-1'
            },
            {
                name: 'blue-grey darken-2'
            },
            {
                name: 'blue-grey darken-3'
            },
            {
                name: 'blue-grey darken-4'
            },
            {
                name: 'grey'
            },
            {
                name: 'grey lighten-5'
            },
            {
                name: 'grey lighten-4'
            },
            {
                name: 'grey lighten-3'
            },
            {
                name: 'grey lighten-2'
            },
            {
                name: 'grey lighten-1'
            },
            {
                name: 'grey darken-1'
            },
            {
                name: 'grey darken-2'
            },
            {
                name: 'grey darken-3'
            },
            {
                name: 'grey darken-4'
            },
        ]
      };
    },
    watch: {
      searchParts(val) {
        val && val !== this.newPart.user_id && this.findPart(val);
      },
    },
    created() {
      if (this.$can("ad_view")) {
        this.startAdLoader();
        this.fetchAds(this.adPagination.current_page);
        // this.getFormData();
      }
    },
    methods: {
      ...mapActions([
        "fetchAds",
        "startAdLoader",
        "stopAdLoader",
        "filterAds",
  
        "filterLogData",
        "openLogDataModel",
      ]),
     
      enableSearch() {
        this.searchButtonDisabled = false;
      },     
     
      resetSearch() {
        this.searchTerm = null;
        this.filteredAds = true;
        this.startAdLoader();
        this.fetchAds(1);
      },
      
      showAd(ad) {
        this.assignedAd = ad;

        this.changeView("show");
      },
  
      editAd(ad) {
        this.newAd.id = ad.id;
        this.newAd.title = ad.title;
        this.newAd.tagline = ad.tagline;
        this.newAd.description = ad.description;
        this.newAd.text_color = ad.text_color;
        this.newAd.background_color = ad.background_color;
  
        this.changeView("create");
      },
  
      uploadImage() {
        if (this.$refs.imageForm.validate()) {
          this.imageLoading = true;
          let formData = new FormData();
  
          // files
  
          formData.append("files", this.imageFile, this.imageFile.name);
          formData.append("id", this.assignedAd.id);
  
          apiCall({
            url: "/api/ad?type=image",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.snackbar = true;
              this.message = "Logo Uploaded Successfully";
              this.color = "success";
              this.fetchAds(this.adPagination.current_page);
              this.imageLoading = false;
              this.imageDialog = false;
              this.imageFile = null;
              this.assignedAd.image = resp.image;
              // this.changeView('default')
            })
            .catch((error) => {
              this.imageLoading = false;
            });
        }
      },

      save() {
        if (this.$refs.form.validate()) {
          if (this.newAd.id != null) {
            confirm("Are you sure you want to update ad?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/ad/" + this.newAd.id,
                data: this.newAd,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Ad Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchAds(this.adPagination.current_page);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create ad?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/ad",
                data: this.newAd,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Ad Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchAds(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchAds(1);
                });
            }
          }
        }
      },
  
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startAdLoader();
          apiCall({
            url: "/api/ad?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterAds(resp);
              this.searchLoader = false;
              this.stopAdLoader();
              this.filteredAds = true;
            })
            .catch((error) => {
              console.log(error);
              this.searchLoader = false;
              this.stopAdLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
      activateAd(item) {
        confirm("Are You Sure You Want to Activate Ad?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.adIndex = item.id;
          this.newAd.id = item.id;
          apiCall({
            url: "/api/ad?type=activate",
            data: this.newAd,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ad Account Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.adIndex = null;
              this.fetchAds(this.adPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.AdIndex = null;
              console.log(error);
            });
        }
      },
      deactivateAd(item) {
        confirm("Are You Sure You Want to Deactivate Ad?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.adIndex = item.id;
          this.newAd.id = item.id;
          apiCall({
            url: "/api/ad?type=deactivate",
            data: this.newAd,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ad Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.adIndex = null;
              this.fetchAds(this.adPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.adIndex = null;
              console.log(error);
            });
        }
      },
      
      changeView(view) {
        if (view == "default") {
            this.newAd.id = null;
            this.newAd.title = null;
            this.newAd.tagline = null;
            this.newAd.description = null;
            this.newAd.background_color = null;
            this.newAd.text_color = null;

        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
        }
        this.view = view;
      },
      
      changePage() {
        this.startAdLoader();
        if (this.filteredAds == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/ad?page=" +
              this.adPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterAds(resp);
              this.stopAdLoader();
            })
            .catch((error) => {
              console.log(error);
              this.stopAdLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchAds(this.adPagination.current_page);
        }
      },
      deleteAd(item) {
        confirm("Are You Sure You Want to Delete Ad") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.adIndex = item.id;
          apiCall({ url: "/api/ad/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Ad Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.adIndex = null;
              this.fetchAds(this.adPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.adIndex = null;
              console.log(error);
            });
        }
      },
      
      getFormData() {
        apiCall({
          url: "/api/ad?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\Ad";
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "ads",
        "adPagination",
        "adLoader",
        "logDataModel"
      ]),
      length: function () {
        return Math.ceil(
          this.adPagination.total / this.adPagination.per_page
        );
      },
      adAdministratorLength: function () {
        return Math.ceil(
          this.adPartPagination.total /
            this.adPartPagination.per_page
        );
      },
    
      dateToday() {
        var today = new Date();
  
        var timestamp =
          today.getFullYear() +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
    },
  };
  </script>
  