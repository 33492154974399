<template>
    <div class="students" v-if="$can('student_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-dialog
            v-model="excelDialog"
            width="1500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Student Upload
                <v-spacer></v-spacer>
                <v-btn icon @click="excelDialog = false">
                    <v-icon> mdi-close </v-icon>
                </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-layout column>
                        <v-flex xs12>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead class="header">
                                        <tr class="accent">
                                            <th class="text-left text--text">NAME</th>
                                            <th class="text-left text--text">MIDDLE_NAME </th>
                                            <th class="text-left text--text">SURNAME</th>
                                            <th class="text-left text--text">FATHER</th>
                                            <th class="text-left text--text">MOTHER</th>
                                            <th class="text-left text--text">F_MOBILE</th>
                                            <th class="text-left text--text">M_MOBILE </th>
                                            <th class="text-left text--text">F_EMAIL </th>
                                            <th class="text-LEFT text--text">M_EMAIL </th>
                                            <th class="text-left text--text">CLASS </th>
                                            <th class="text-left text--text">ADM_YEAR </th>
                                            <th class="text-left text--text">ADM_NO </th>

                                            <th class="text-left text--text">ACC_NO </th>
                                        </tr>
                                    </thead>
                                </template>
                            </v-simple-table>
                        </v-flex>
                        <v-flex xs12>
                            <div class="mx-3 my-5">
                                <v-form ref="excelForm" v-model="excelValid" lazy-validation>
                                    <v-layout row wrap>
                                    <v-flex xs10>
                                        <v-file-input :loading="excelLoading" :rules="fileRules" outlined dense show-size chips
                                        accept=".xlsx, .xls, .csv" v-model="excelFile" label="Excel File" class="text_field background">
                                        </v-file-input>
                                    </v-flex>
                                    <v-flex xs2>
                                        <div align="right">
                                        <v-btn rounded depressed class="primary text-none" :disabled="!excelValid" @click="saveExcel"
                                            :loading="excelLoading">
                                            Upload
                                            <v-icon right>
                                            mdi-cloud-upload
                                            </v-icon>
                                        </v-btn>
                                        </div>
                                    </v-flex>
                                    </v-layout>
                                </v-form>
                            </div>
                        </v-flex>
                    </v-layout>
                    
                </v-card-text>
            </v-card>
            </v-dialog>
        <div class="pa-5">
            <v-layout column>
                <div v-if="view == 'default'">
                    <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                            <div class="mx-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md6>
                                        <div v-if="length != 0" align="left" class="mt-1">
                                            <b class="display-1 primary--text">Total: </b>{{ studentPagination.total |
                                                formatNumber }}
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="pa-1">
                                            <v-text-field class="text_field background" outlined dense rounded
                                                label="Search" append-icon="mdi-undo-variant" @click:append="resetSearch()"
                                                v-on:keyup.enter="search" v-model="searchTerm"
                                                @input="enableSearch()"></v-text-field>
                                        </div>

                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1" align="right">
                                            <v-btn v-if="$can('student_create')" class="button mx-1" icon @click="excelDialog = true">
                                                <v-icon class="excel--text">mdi-microsoft-excel</v-icon>
                                            </v-btn>

                                            <v-btn class="button mx-1" icon @click="search" :loading="searchLoader"
                                                :disabled="searchButtonDisabled">
                                                <v-icon class="secondary--text">mdi-magnify</v-icon>
                                            </v-btn>

                                            <v-btn icon class="button mx-1" @click="changeView('create')"
                                                v-if="$can('student_create')">

                                                <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-progress-linear v-if="studentLoader" height="1" indeterminate
                            color="primary"></v-progress-linear>
                        <v-flex xs12 class="mt-5">
                            <div v-if="students.length == 0">
                                <v-card elevation="0">
                                    <v-layout row wrap>
                                        <v-flex xs12 md1>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        <v-icon large class="text--text">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                        <v-flex xs12 md11>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center"> No Student Found </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                            <div v-else>
                                <div class="hidden-sm-and-down">
                                    <v-card elevation="0" class="pa-2">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead class="header">
                                                    <tr class="accent">
                                                        <th class="text-left text--text">Pic</th>
                                                        <th class="text-left text--text">Name</th>
                                                        <th class="text-right text--text">ADM No</th>
                                                        <th class="text-right text--text">ACC No</th>
                                                        <th class="text-right text--text">ADM Year</th>
                                                        <th class="text-left text--text">Account Active</th>
                                                        <th class="text-left text--text">Account Creation Date</th>
                                                        <th class="text-right text--text">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in students" :key="item.id">
                                                        <td>
                                                            <v-avatar size="48" v-if="item.prof_pic == null">
                                                                <v-img max-height="150" src="img/avatar.jpg">
                                                                </v-img>
                                                            </v-avatar>
                                                            <v-avatar size="48" v-else>
                                                                <v-img max-height="150"
                                                                    :src="path + '/storage/profile_pics/' + item.prof_pic">
                                                                </v-img>
                                                            </v-avatar>
                                                        </td>
                                                        <td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name
                                                        }}</td>
                                                        <td>
                                                            <div align="right">
                                                                {{ item.admission_no }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                {{ item.account_no }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                {{ item.admission_year }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-if="item.active == 1">Yes</div>
                                                            <div v-else>No</div>
                                                        </td>
                                                        <td>
                                                            {{
                                                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                                            }}
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                <v-tooltip top v-if="$can('student_view')">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 universal--text"
                                                                            @click="showStudent(item)">
                                                                            <v-icon small> mdi-eye </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>View</span>
                                                                </v-tooltip>
                                                                <v-tooltip top v-if="$can('student_edit')">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 success--text"
                                                                            @click="editStudent(item)" v-bind="attrs"
                                                                            v-on="on">
                                                                            <v-icon small> mdi-fountain-pen-tip </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>

                                                                <v-tooltip top v-if="$can('student_archive')">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 error--text"
                                                                            @click="deleteStudent(item)"
                                                                            :loading="loading && studentIndex == item.id"
                                                                            v-bind="attrs" v-on="on">
                                                                            <v-icon small> mdi-trash-can </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>delete</span>
                                                                </v-tooltip>

                                                                <v-tooltip top
                                                                    v-if="$can('student_activate') && item.active == 0">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 red--text"
                                                                            @click="activateStudent(item)"
                                                                            :loading="activateLoading && studentIndex == item.id"
                                                                            v-bind="attrs" v-on="on">
                                                                            <v-icon small> mdi-toggle-switch-off-outline
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Activate Account</span>
                                                                </v-tooltip>
                                                                <v-tooltip top
                                                                    v-if="$can('student_deactivate') && item.active == 1">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 green--text"
                                                                            @click="deactivateStudent(item)"
                                                                            :loading="deactivateLoading && studentIndex == item.id"
                                                                            v-bind="attrs" v-on="on">
                                                                            <v-icon small> mdi-toggle-switch </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Deactivate Account</span>
                                                                </v-tooltip>
                                                                <v-tooltip top v-if="$can('log_view')">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 purple--text"
                                                                            @click="viewLog(item)" v-bind="attrs" v-on="on">
                                                                            <v-icon small> mdi-script-text </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Log</span>
                                                                </v-tooltip>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                </div>
                                <div class="hidden-md-and-up">
                                    <v-layout column>
                                        <template v-for="(student, index) in students">
                                            <div :key="index">
                                                <v-flex xs12 class="mb-2">
                                                    <v-card elevation="0" style="border-radius: 25px;" outlined>
                                                        <div class="pa-5">
                                                            <v-layout column>
                                                                <v-flex xs12>
                                                                    <v-layout row wrap>
                                                                        <v-flex xs2>
                                                                        <div align="center">
                                                                        <v-avatar size="48" v-if="student.display_img==null">
                                                                        <v-img
                                                                            max-height="150"
                                                                            
                                                                            src="img/avatar.jpg"
                                                                        >
                                                                        </v-img>
                                                                        </v-avatar>
                                                                        <v-avatar size="48" v-else>
                                                                        <v-img
                                                                            max-height="150"
                                                                            :src="path+'/storage/profile_pics/'+student.display_img"
                                                                            >
                                                                            </v-img>
                                                                        </v-avatar>
                                                                    </div>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                        <div class="title primary--text ml-3">
                                                                        <b>{{ student.first_name }} {{ student.middle_name }} {{ student.last_name }}</b>
                                                                        </div>
                                                                    </v-flex>
                                                                    <v-flex xs1>
                                                                        <v-btn
                                                                        depressed
                                                                        class="red--text"
                                                                        :loading="
                                                                            loading && studentIndex == student.id
                                                                        "
                                                                        icon
                                                                        @click="deleteStudent(student)"
                                                                        v-if="$can('student_archive')"
                                                                        >
                                                                        <v-icon> mdi-delete </v-icon>
                                                                        </v-btn>
                                                                    </v-flex>
                                                                    </v-layout>
                                                                    
                                                                </v-flex>
                                                                <v-divider class="mt-7 mb-3"></v-divider>

                                                            
                                                                <v-flex xs12 class="mt-1">
                                                                    <v-layout column>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <div>
                                                                                <b>ADM No</b>
                                                                            </div>
                                                                            <div>{{ student.admission_no }}</div>
                                                                        </v-flex>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <div>
                                                                                <b>ACC No</b>
                                                                            </div>
                                                                            <div>{{ student.account_no }}</div>
                                                                        </v-flex>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <div>
                                                                                <b>ADM Year</b>
                                                                            </div>
                                                                            <div>{{ student.admission_year }}</div>
                                                                        </v-flex>

                                                                        <v-flex xs12 class="mt-1" v-if="student.email != null">
                                                                            <div>
                                                                                <b>Email Address</b>
                                                                            </div>
                                                                            <div>{{ student.email }}</div>
                                                                        </v-flex>
                                                                        <v-flex xs12 class="mt-1" v-if="student.phone != null">
                                                                            <div>
                                                                                <b>Phone Number</b>
                                                                            </div>
                                                                            <div>{{ student.phone }}</div>
                                                                        </v-flex>

                                                                        <v-flex xs12 class="mt-1">
                                                                            <div>
                                                                                <b>Account Active</b>
                                                                            </div>
                                                                            <div>
                                                                                <div v-if="student.active == 1">Yes</div>
                                                                                <div v-else>No</div>
                                                                            </div>
                                                                        </v-flex>
                                                                        <v-flex xs12 class="mt-1">
                                                                            <div>
                                                                                <b>Account Creation Date</b>
                                                                            </div>
                                                                            <div>
                                                                                {{
                                                                                    student.created_at
                                                                                    | moment("DD/MM/YYYY - hh:mm a")
                                                                                }}
                                                                            </div>
                                                                        </v-flex>



                                                                        <v-flex xs12 class="mt-5">
                                                                            <v-layout row wrap>
                                                                                <v-flex xs12 v-if="$can('student_view')">
                                                                                    <div class="pa-1">
                                                                                        <v-btn 
                                                                                            rounded
                                                                                            depressed class="
                                                                                                primary
                                                                                                text-none
                                                                                                white--text
                                                                                            " block @click="showStudent(student)">View
                                                                                            <v-icon right> mdi-eye </v-icon>
                                                                                        </v-btn>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 v-if="$can('student_edit')">
                                                                                    <div class="pa-1">
                                                                                        <v-btn depressed rounded
                                                                                            class="blue text-none white--text"
                                                                                            block
                                                                                            @click="editStudent(student)"
                                                                                            >Edit
                                                                                            <v-icon right>
                                                                                                mdi-border-color
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 v-if="$can('student_activate') && student.active == 0">
                                                                                    <div class="pa-1">
                                                                                        <v-btn depressed
                                                                                            rounded
                                                                                            class="green text-none white--text"
                                                                                            block
                                                                                            @click="activateStudent(student)"
                                                                                            :loading="activateLoading && studentIndex == student.id"
                                                                                            >Activate
                                                                                            Account
                                                                                            <v-icon right>
                                                                                                mdi-toggle-switch-off-outline
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 v-if="$can('student_deactivate') && student.active == 1">
                                                                                    <div class="pa-1">
                                                                                        <v-btn depressed rounded
                                                                                            class="red text-none white--text"
                                                                                            block
                                                                                            @click="deactivateStudent(student)"
                                                                                            :loading="deactivateLoading && studentIndex == student.id"
                                                                                            >Deactivate
                                                                                            Account
                                                                                            <v-icon right>
                                                                                                mdi-toggle-switch
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </div>
                                                                                </v-flex>
                                                                                <v-flex xs12 v-if="$can('log_view')">
                                                                                    <div class="pa-1">
                                                                                        <v-btn rounded
                                                                                            depressed class="
                                                    purple
                                                    text-none
                                                    white--text
                                                  " block @click="viewLog(student)">Log
                                                                                            <v-icon right> mdi-script-text
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </div>
                                                                                </v-flex>
                                                                            </v-layout>
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                            </v-layout>
                                                        </div>
                                                    </v-card>
                                                </v-flex>
                                            </div>
                                        </template>
                                    </v-layout>
                                </div>
                            </div>
                        </v-flex>
                    </v-card>

                    <v-layout column class="mt-5">
                        <v-flex xs12>
                            <div align="center">
                                <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                    v-model="studentPagination.current_page" @input="changePage()" circle>
                                </v-pagination>
                            </div>
                        </v-flex>
                        <v-flex xs12>

                        </v-flex>
                    </v-layout>
                </div>
                <div v-if="view == 'create'">
                    <v-flex class="mt-5">
                        <v-card outlined>
                            <v-card-title class="accent text--text">
                                <v-spacer></v-spacer>
                                <div v-if="newStudent.id == null">Add Student</div>
                                <div v-else>Edit Student</div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="changeView('default')">
                                    <v-icon class="text--text"> mdi-close </v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-form v-model="valid" ref="form" lazy-validation>
                                    <v-layout column>
                                        <v-flex xs12>
                                            <v-layout row wrap class="mt-5">
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>First Name<span class="error--text">*</span></div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.first_name"
                                                            :rules="inputRules"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Middle Name</div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.middle_name"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Last Name<span class="error--text">*</span></div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.last_name"
                                                            :rules="inputRules"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Admission No<span class="error--text">*</span></div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.admission_no"
                                                            :rules="inputRules"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Account No<span class="error--text">*</span></div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.account_no"
                                                            :rules="inputRules"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Admission Year<span class="error--text">*</span></div>
                                                        <v-select :items="years" v-model="newStudent.admission_year"
                                                    class="text_field background"
                                                    outlined dense :rules="inputRules"></v-select>

                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>
                                                            Email Address
                                                        </div>
                                                        <v-text-field class="text_field background" outlined dense
                                                            v-model="newStudent.email" :disabled="lockEmail"
                                                            :rules="emailRules"></v-text-field>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="pa-3">
                                                        <div>Phone Number</div>
                                                        <v-text-field class="text_field background" outlined
                                                            dense v-model="newStudent.phone_number"></v-text-field>
                                                    </div>
                                                </v-flex>

                                            </v-layout>
                                        </v-flex>


                                    </v-layout>

                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
                                    :loading="loading">
                                    Save
                                    <v-icon right> mdi-content-save </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </div>
                <div v-if="view == 'show'">
                    <v-card outlined class="mt-5">
                        <v-card-title class="accent text--text">
                            {{ assignedStudent.first_name }} {{ assignedStudent.middle_name }} {{ assignedStudent.last_name
                            }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="changeView('default')">
                                <v-icon class="text--text"> mdi-close </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-3">
                                <v-tabs show-arrows v-model="studentTab">
                                    <v-tabs-slider color="primary"></v-tabs-slider>

                                    <v-tab @click="activeTab = 'details'">
                                        <div class="text-none primary--text">Details</div>
                                    </v-tab>
                                    <v-tab @click="activeTab = 'userStudents'" v-if="$can('user_view')">
                                        <div class="text-none primary--text">
                                            Parent(s)/Guardian(s) ({{
                                                userStudentPagination.total
                                            }})
                                        </div>
                                    </v-tab>
                                    <v-tab @click="activeTab = 'studentLevels'" v-if="$can('sub_level_view')">
                                        <div class="text-none primary--text">
                                            Sub Level(s) ({{
                                                studentLevelPagination.total
                                            }})
                                        </div>
                                    </v-tab>
                                </v-tabs>
                                <div v-if="activeTab == 'details'">
                                    <div class="mx-3">
                                    <v-layout column>
                                        <v-flex xs12 class="mt-8">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Admission No:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.admission_no }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Account No:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.account_no }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Admission Year:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.admission_year }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5" v-if="assignedStudent.email != null">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Email:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.email }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5" v-if="assignedStudent.phone != null">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Phone Number:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.phone }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Account Creation Date:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    {{ assignedStudent.created_at | moment("DD/MM/YYYY - hh:mm a") }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                            <v-layout row wrap>
                                                <v-flex xs12 md3>
                                                    <div><b>Account Active:</b></div>
                                                </v-flex>
                                                <v-flex xs12 md9>
                                                    <div v-if="assignedStudent.active == 1">Yes</div>
                                                    <div v-else>No</div>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>

                                    </v-layout>
                                    </div>
                                </div>
                                <div v-if="activeTab == 'userStudents'">
                                    <div v-if="userStudentView == 'default'">
                                        <v-card elevation="0" class="mt-5">
                                            <v-flex xs12 class="mt-10 mb-2">
                                                <div class="mx-5">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md6>
                                                            <div v-if="userStudentLength != 0" align="left" class="mt-1">
                                                                <b class="display-1 primary--text">Total: </b>{{
                                                                    userStudentPagination.total
                                                                    | formatNumber
                                                                }}
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs12 md4>
                                                            <div class="pa-1">
                                                                <v-text-field class="text_field background" outlined dense
                                                                    rounded label="Search" append-icon="mdi-undo-variant"
                                                                    @click:append="
                                                                        resetUserStudentSearch()
                                                                        " v-on:keyup.enter="userStudentSearch
        " v-model="userStudentSearchTerm" @input="
        enableUserStudentSearch()
        "></v-text-field>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs12 md2>
                                                            <div class="pa-1" align="right">
                                                                <v-btn class="button mx-1" icon @click="userStudentSearch" 
                                                                :loading="userStudentSearchLoader
                                                                        " :disabled="userStudentSearchButtonDisabled
        ">
                                                                    <v-icon class="secondary--text">mdi-magnify</v-icon>
                                                                </v-btn>
                                                                <v-btn v-if="$can('user_create')" icon class="button mx-1" @click="
                                                                    changeUserStudentView(
                                                                        'create'
                                                                    )">
                                                                    <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                            </v-flex>
                                            <v-divider class="mt-9 mx-5"></v-divider>
                                            <v-flex xs12 class="mt-5">
                                                <div v-if="userStudents.length == 0">
                                                    <v-card elevation="0">
                                                        <v-layout row wrap>
                                                            <v-flex xs12 md1>
                                                                <v-container fill-height fluid>
                                                                    <v-row align="center" justify="center">
                                                                        <v-col class="text-center">
                                                                            <v-icon large class="text--text">
                                                                                mdi-alert-circle-outline
                                                                            </v-icon>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-flex>
                                                            <v-flex xs12 md11>
                                                                <v-container fill-height fluid>
                                                                    <v-row align="center" justify="center">
                                                                        <v-col class="text-center">
                                                                            No Parent/Guardian Found
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card>
                                                </div>
                                                <div v-else>
                                                    <div class="hidden-sm-and-down">
                                                        <v-card elevation="0" class="pa-2">
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                    <thead class="header">
                                                                        <tr class="accent">
                                                                            <th class="text-left text--text">Pic</th>
                                                                            <th class="text-left text--text">Name</th>
                                                                            <th class="text-left text--text">Email Address
                                                                            </th>
                                                                            <th class="text-left text--text">Phone #</th>
                                                                            <th class="text-left text--text">Status</th>
                                                                            <th class="text-right text--text">Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in userStudents"
                                                                            :key="item.id">
                                                                            <td>
                                                                                <div class="my-2">
                                                                                    <v-avatar size="48"
                                                                                        class="primary white--text"
                                                                                        v-if="item.user.prof_pic == null">
                                                                                        {{ item.user.first_name[0] }}
                                                                                    </v-avatar>
                                                                                    <v-avatar size="48" v-else>
                                                                                        <v-img
                                                                                            :src="path + '/storage/profile_pics/' + item.user.prof_pic">
                                                                                        </v-img>
                                                                                    </v-avatar>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {{ item.user.first_name }}
                                                                                {{ item.user.middle_name }}
                                                                                {{ item.user.last_name }}
                                                                            </td>
                                                                            <td>{{ item.user.email }}</td>
                                                                            <td>{{ item.user.phone }}</td>
                                                                            <td>
                                                                                <div v-if="item.status == 1">
                                                                                    Active
                                                                                </div>
                                                                                <div v-else>Inactive</div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    <v-btn v-if="$can('user_archive')" icon class="button mr-1 error--text" @click="
        deleteUserStudent(
            item
        )
        " :loading="loading &&
        userStudentIndex ==
        item.id
        ">
                                                                                        <v-icon small> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                    <v-tooltip top v-if="$can('user_activate') && item.status == 0
                                                                                        ">
                                                                                        <template
                                                                                            v-slot:activator="{ on, attrs }">
                                                                                            <v-btn icon
                                                                                                class="button mr-1 red--text"
                                                                                                @click="
                                                                                                    activateUserStudent(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
        userStudentIndex ==
        item.id
        " v-bind="attrs" v-on="on">
                                                                                                <v-icon small>
                                                                                                    mdi-toggle-switch-off-outline
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Activate Parent</span>
                                                                                    </v-tooltip>
                                                                                    <v-tooltip top v-if="$can('user_deactivate') && item.status == 1
                                                                                        ">
                                                                                        <template
                                                                                            v-slot:activator="{ on, attrs }">
                                                                                            <v-btn icon
                                                                                                class="button mr-1 green--text"
                                                                                                @click="
                                                                                                    deactivateUserStudent(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
        userStudentIndex ==
        item.id
        " v-bind="attrs" v-on="on">
                                                                                                <v-icon small>
                                                                                                    mdi-toggle-switch
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Deactivate
                                                                                            Parent</span>
                                                                                    </v-tooltip>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </div>
                                                    <div class="hidden-md-and-up">
                                                        <div>
                                                            <v-layout column>
                                                                <template v-for="(
                                      administrator, index
                                    ) in userStudents">
                                                                    <div :key="index">
                                                                        <v-flex xs12 class="mb-2">
                                                                            <v-card elevation="0" style="border-radius: 25px;" outlined>
                                                                                <div class="pa-2 mt-2">
                                                                                    <div class="pa-5">
                                                                                        <v-layout column>
                                                                                            <v-flex xs12>
                                                                                                <v-layout row wrap>
                                                                                                    <v-flex xs2>
                                                                                                    <div align="center">
                                                                                                    <v-avatar size="48" v-if="administrator.user.prof_pic==null">
                                                                                                    <v-img
                                                                                                        max-height="150"
                                                                                                        
                                                                                                        src="img/avatar.jpg"
                                                                                                    >
                                                                                                    </v-img>
                                                                                                    </v-avatar>
                                                                                                    <v-avatar size="48" v-else>
                                                                                                    <v-img
                                                                                                        max-height="150"
                                                                                                        :src="path+'/storage/profile_pics/'+administrator.user.prof_pic"
                                                                                                        >
                                                                                                        </v-img>
                                                                                                    </v-avatar>
                                                                                                </div>
                                                                                                </v-flex>
                                                                                                <v-flex xs9>
                                                                                                    <div class="title primary--text ml-3">
                                                                                                    <b>{{ administrator.user.first_name }} {{ administrator.user.middle_name }} {{ administrator.user.last_name }}</b>
                                                                                                    </div>
                                                                                                </v-flex>
                                                                                                <v-flex xs1>
                                                                                                    <v-btn depressed v-if="$can('user_archive')"
                                                                                                            class="red--text"
                                                                                                            :loading="loading &&
                                                                                                                userStudentIndex ==
                                                                                                                administrator.id
                                                                                                                " icon @click="
            deleteUserStudent(
                administrator
            )
            ">
                                                                                                            <v-icon>
                                                                                                                mdi-delete
                                                                                                            </v-icon>
                                                                                                        </v-btn>
                                                                                                </v-flex>
                                                                                                </v-layout>
                                                                                                
                                                                                            </v-flex>
                                                                                            <v-divider class="mt-7 mb-3"></v-divider>
                                                                                           
                                                                                            <v-flex xs12 class="mt-1">
                                                                                                <v-layout column>
                                                                                                    <v-flex xs12 class="mt-5">
                                                                                                        <v-layout row wrap>
                                                                                                            <v-flex xs4>
                                                                                                                <b>Email
                                                                                                                    Address</b>
                                                                                                            </v-flex>
                                                                                                            <v-flex xs8>
                                                                                                                {{
                                                                                                                    administrator.user.email
                                                                                                                }}
                                                                                                            </v-flex>
                                                                                                        </v-layout>
                                                                                                    </v-flex>
                                                                                                    <v-flex xs12 class="mt-5">
                                                                                                        <v-layout row wrap>
                                                                                                            <v-flex xs4>
                                                                                                                <b>Phone #</b>
                                                                                                            </v-flex>
                                                                                                            <v-flex xs8>
                                                                                                                {{
                                                                                                                    administrator.user.phone
                                                                                                                }}
                                                                                                            </v-flex>
                                                                                                        </v-layout>
                                                                                                    </v-flex>
                                                                                                    <v-flex xs12 class="mt-5">
                                                                                                        <v-layout row wrap>
                                                                                                            <v-flex xs4>
                                                                                                                <b>Code</b>
                                                                                                            </v-flex>
                                                                                                            <v-flex xs8>
                                                                                                                {{
                                                                                                                    administrator.user.code
                                                                                                                }}
                                                                                                            </v-flex>
                                                                                                        </v-layout>
                                                                                                    </v-flex>
                                                                                                    <v-flex xs12 class="mt-5">
                                                                                                        <v-layout row wrap>
                                                                                                            <v-flex xs4>
                                                                                                                <b>Status</b>
                                                                                                            </v-flex>
                                                                                                            <v-flex xs8>
                                                                                                                <div v-if="administrator.status ==
                                                                                                                        1
                                                                                                                        ">
                                                                                                                    Active
                                                                                                                </div>
                                                                                                                <div v-else>
                                                                                                                    Inactive
                                                                                                                </div>
                                                                                                            </v-flex>
                                                                                                        </v-layout>
                                                                                                    </v-flex>

                                                                                                    <v-flex xs12 class="mt-5">
                                                                                                        <v-layout row wrap>
                                                                                                           
                                                                                                            <v-flex xs12>
                                                                                                                <div>
                                                                                                                    <v-btn
                                                                                                                        rounded
                                                                                                                        depressed
                                                                                                                        class="green darken-1 text-none white--text mt-1"
                                                                                                                        block
                                                                                                                        :loading="activateLoading &&
                                                                                                                            userStudentIndex ==
                                                                                                                            administrator.id
                                                                                                                            " @click="
            activateUserStudent(
                administrator
            )
            " v-if="$can('user_activate') && administrator.status == 0
            ">Activate Parent
                                                                                                                        <v-icon
                                                                                                                            right>
                                                                                                                            mdi-toggle-switch
                                                                                                                        </v-icon>
                                                                                                                    </v-btn>
                                                                                                                </div>
                                                                                                            </v-flex>
                                                                                                            <v-flex xs12>
                                                                                                                <div>
                                                                                                                    <v-btn
                                                                                                                        rounded
                                                                                                                        depressed
                                                                                                                        class="red darken-1 text-none white--text mt-1"
                                                                                                                        block
                                                                                                                        :loading="deactivateLoading &&
                                                                                                                            userStudentIndex ==
                                                                                                                            administrator.id
                                                                                                                            " @click="
            deactivateUserStudent(
                administrator
            )
            " v-if="$can('user_deactivate') && administrator.status == 1
            ">Deactivate Parent
                                                                                                                        <v-icon
                                                                                                                            right>
                                                                                                                            mdi-toggle-switch-off-outline
                                                                                                                        </v-icon>
                                                                                                                    </v-btn>
                                                                                                                </div>
                                                                                                            </v-flex>
                                                                                                        </v-layout>
                                                                                                    </v-flex>
                                                                                                </v-layout>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </div>
                                                                                        
                                                                                </div>
                                                                            </v-card>
                                                                        </v-flex>
                                                                    </div>
                                                                </template>
                                                            </v-layout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-flex>
                                        </v-card>
                                        <v-layout column class="mt-5">
                                            <v-flex xs12>
                                                <div align="left">
                                                    <v-pagination v-if="length != 0" :length="userStudentLength"
                                                        total-visible="10" v-model="userStudentPagination.current_page
                                                            " @input="changeUserStudentPage()" circle>
                                                    </v-pagination>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                    <div v-if="userStudentView == 'create'">
                                        <v-flex class="mt-5">
                                            <v-card-title class="accent text--text">
                                                <v-spacer></v-spacer>
                                                <div v-if="newParent.id == null">
                                                    Add Parent/Guardian
                                                </div>
                                                <div v-else>Edit Parent</div>
                                                <v-spacer></v-spacer>
                                                <v-btn icon @click="
                                                    changeUserStudentView('default')
                                                    ">
                                                    <v-icon class="text--text"> mdi-close </v-icon>
                                                </v-btn>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-form v-model="valid" ref="userStudentForm" lazy-validation>
                                                    <v-layout row wrap class="mt-5">
                                                        <v-flex xs12 md4>
                                                            <div class="pa-3">
                                                                <div>
                                                                    Parent/Guardian<span class="error--text">*</span>
                                                                </div>
                                                                <v-autocomplete v-model="newParent.user_id"
                                                                    :loading="loading" :items="items"
                                                                    :search-input.sync="searchUsers" item-value="id"
                                                                    item-text="first_name"
                                                                    class="text_field background my-2" flat hide-no-data
                                                                    hide-details outlined dense :rules="inputRules">
                                                                    <template v-slot:selection="{ item }">
                                                                        {{ getText(item) }}
                                                                    </template>
                                                                    <template v-slot:item="{ item }">
                                                                        {{ getText(item) }}
                                                                    </template>
                                                                </v-autocomplete>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-form>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn depressed class="primary text-none mt-3"
                                                    @click="saveUserStudent" :disabled="!valid" :loading="loading">
                                                    Save
                                                    <v-icon right> mdi-content-save </v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-flex>
                                    </div>
                                </div>
                                <div v-if="activeTab == 'studentLevels'">
                                    <div v-if="studentLevelView == 'default'">
                                        <v-card elevation="0" class="mt-5">
                                            <v-flex xs12 class="mt-10 mb-2">
                                                <div class="mx-5">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md6>
                                                            <div v-if="studentLevelLength != 0" align="left" class="mt-1">
                                                                <b class="display-1 primary--text">Total: </b>{{
                                                                    studentLevelPagination.total
                                                                    | formatNumber
                                                                }}
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs12 md4>
                                                            <div class="pa-1">
                                                                <v-text-field class="text_field background" outlined dense
                                                                    rounded label="Search" append-icon="mdi-undo-variant"
                                                                    @click:append="
                                                                        resetStudentLevelSearch()
                                                                        " v-on:keyup.enter="studentLevelSearch
        " v-model="studentLevelSearchTerm" @input="
        enableStudentLevelSearch()
        "></v-text-field>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs12 md2>
                                                            <div class="pa-1" align="right">
                                                                <v-btn class="button mx-1" icon @click="studentLevelSearch" 
                                                                :loading="studentLevelSearchLoader
                                                                        " :disabled="studentLevelSearchButtonDisabled
        ">
                                                                    <v-icon class="secondary--text">mdi-magnify</v-icon>
                                                                </v-btn>
                                                                <v-btn v-if="$can('sub_level_create')" icon class="button mx-1" @click="
                                                                    changeStudentLevelView(
                                                                        'create'
                                                                    )">
                                                                    <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                            </v-flex>
                                            <v-divider class="mt-9 mx-5"></v-divider>
                                            <v-flex xs12 class="mt-5">
                                                <div v-if="studentLevels.length == 0">
                                                    <v-card elevation="0">
                                                        <v-layout row wrap>
                                                            <v-flex xs12 md1>
                                                                <v-container fill-height fluid>
                                                                    <v-row align="center" justify="center">
                                                                        <v-col class="text-center">
                                                                            <v-icon large class="text--text">
                                                                                mdi-alert-circle-outline
                                                                            </v-icon>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-flex>
                                                            <v-flex xs12 md11>
                                                                <v-container fill-height fluid>
                                                                    <v-row align="center" justify="center">
                                                                        <v-col class="text-center">
                                                                            No Level Found
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card>
                                                </div>
                                                <div v-else>
                                                    <div class="hidden-sm-and-down">
                                                        <v-card elevation="0" class="pa-2">
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                    <thead class="header">
                                                                        <tr class="accent">
                                                                            <th class="text-left text--text">Level</th>
                                                                            <th class="text-left text--text">Sub Level</th>
                                                                            <th class="text-left text--text">Status</th>
                                                                            <th class="text-right text--text">Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in studentLevels"
                                                                            :key="item.id">
                                                                            <td>{{ item.level.name }}</td>
                                                                            <td>{{ item.sub_level.name }}</td>
                                                                            <td>
                                                                                <div v-if="item.status == 1">
                                                                                    Active
                                                                                </div>
                                                                                <div v-else>Inactive</div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    
                                                                                    <v-btn v-if="$can('sub_level_archive')" icon class="button mr-1 error--text" @click="
                                                                                        deleteStudentLevel(
                                                                                            item
                                                                                        )
                                                                                        " :loading="loading &&
                                                                                        studentLevelIndex ==
                                                                                        item.id
                                                                                        ">
                                                                                        <v-icon small> mdi-delete </v-icon>
                                                                                    </v-btn>
                                                                                    <v-tooltip top v-if="$can('sub_level_activate') && item.status == 0
                                                                                        ">
                                                                                        <template
                                                                                            v-slot:activator="{ on, attrs }">
                                                                                            <v-btn icon
                                                                                                class="button mr-1 red--text"
                                                                                                @click="
                                                                                                    activateStudentLevel(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="activateLoading &&
        studentLevelIndex ==
        item.id
        " v-bind="attrs" v-on="on">
                                                                                                <v-icon small>
                                                                                                    mdi-toggle-switch-off-outline
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Activate Level</span>
                                                                                    </v-tooltip>
                                                                                    <v-tooltip top v-if="$can('sub_level_deactivate') &&  item.status == 1
                                                                                        ">
                                                                                        <template
                                                                                            v-slot:activator="{ on, attrs }">
                                                                                            <v-btn icon
                                                                                                class="button mr-1 green--text"
                                                                                                @click="
                                                                                                    deactivateStudentLevel(
                                                                                                        item
                                                                                                    )
                                                                                                    " :loading="deactivateLoading &&
        studentLevelIndex ==
        item.id
        " v-bind="attrs" v-on="on">
                                                                                                <v-icon small>
                                                                                                    mdi-toggle-switch
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Deactivate
                                                                                            Level</span>
                                                                                    </v-tooltip>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </div>
                                                    <div class="hidden-md-and-up">
                                                        <div>
                                                            <v-layout column>
                                                                <template v-for="(
                                      studentLevel, index
                                    ) in studentLevels">
                                                                    <div :key="index">
                                                                        <v-flex xs12 class="mb-2">
                                                                            <v-card elevation="0" style="border-radius: 25px;" outlined>
                                                                                <div class="pa-5 mt-2">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <v-layout row wrap>
                                                                                                <v-flex xs11>
                                                                                                    <div
                                                                                                        class="title primary--text">
                                                                                                        <b>
                                                                                                            {{ studentLevel.sub_level.name }}
                                                                                                    </b>
                                                                                                    </div>
                                                                                                </v-flex>
                                                                                                <v-flex xs1>
                                                                                                    <v-btn depressed v-if="$can('sub_level_archive')"
                                                                                                        class="red--text"
                                                                                                        :loading="loading &&
                                                                                                            studentLevelIndex ==
                                                                                                            studentLevel.id
                                                                                                            " icon @click="
                                                                                                            deleteStudentLevel(
                                                                                                                    studentLevel
                                                                                                                )
                                                                                                                ">
                                                                                                        <v-icon>
                                                                                                            mdi-delete
                                                                                                        </v-icon>
                                                                                                    </v-btn>
                                                                                                </v-flex>
                                                                                            </v-layout>
                                                                                        </v-flex>
                                                                                        <v-divider class="mt-2"></v-divider>
                                                                                        <v-flex xs12 class="mt-1">
                                                                                            <v-layout column>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Level</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ studentLevel.level.name }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                              
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Status</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            <div v-if="studentLevel.status ==
                                                                                                                    1
                                                                                                                    ">
                                                                                                                Active
                                                                                                            </div>
                                                                                                            <div v-else>
                                                                                                                Inactive
                                                                                                            </div>
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>

                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        
                                                                                                        <v-flex xs12>
                                                                                                            <div>
                                                                                                                <v-btn
                                                                                                                    rounded
                                                                                                                    depressed
                                                                                                                    class="green darken-1 text-none white--text mt-1"
                                                                                                                    block
                                                                                                                    :loading="activateLoading &&
                                                                                                                        studentLevelIndex ==
                                                                                                                        studentLevel.id
                                                                                                                        " @click="
        activateStudentLevel(
            studentLevel
        )
        " v-if="$can('sub_level_activate') && studentLevel.status == 0
        ">Activate Sub Level
                                                                                                                    <v-icon
                                                                                                                        right>
                                                                                                                        mdi-toggle-switch
                                                                                                                    </v-icon>
                                                                                                                </v-btn>
                                                                                                            </div>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs12>
                                                                                                            <div>
                                                                                                                <v-btn
                                                                                                                    rounded
                                                                                                                    depressed
                                                                                                                    class="red darken-1 text-none white--text mt-1"
                                                                                                                    block
                                                                                                                    :loading="deactivateLoading &&
                                                                                                                        studentLevelIndex ==
                                                                                                                        studentLevel.id
                                                                                                                        " @click="
        deactivateStudentLevel(
            studentLevel
        )
        " v-if="$can('sub_level_deactivate') && studentLevel.status == 1
        ">Deactivate Level
                                                                                                                    <v-icon
                                                                                                                        right>
                                                                                                                        mdi-toggle-switch-off-outline
                                                                                                                    </v-icon>
                                                                                                                </v-btn>
                                                                                                            </div>
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                            </v-layout>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </div>
                                                                            </v-card>
                                                                        </v-flex>
                                                                    </div>
                                                                </template>
                                                            </v-layout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-flex>
                                        </v-card>
                                        <v-layout column class="mt-5">
                                            <v-flex xs12>
                                                <div align="left">
                                                    <v-pagination v-if="length != 0" :length="studentLevelLength"
                                                        total-visible="10" v-model="studentLevelPagination.current_page
                                                            " @input="changeStudentLevelPage()" circle>
                                                    </v-pagination>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                    <div v-if="studentLevelView == 'create'">
                                        <v-flex class="mt-5">
                                            <v-card-title class="accent text--text">
                                                <v-spacer></v-spacer>
                                                <div v-if="newParent.id == null">
                                                    Add Sub Level
                                                </div>
                                                <div v-else>Edit Parent</div>
                                                <v-spacer></v-spacer>
                                                <v-btn icon @click="
                                                    changeStudentLevelView('default')
                                                    ">
                                                    <v-icon class="text--text"> mdi-close </v-icon>
                                                </v-btn>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-form v-model="valid" ref="studentLevelForm" lazy-validation>
                                                    <v-layout row wrap class="mt-5">
                                                        <v-flex xs12 md4>
                                                            <div class="pa-3">
                                                            <div>Level<span class="error--text">*</span></div>
                                                            <v-select
                                                                :items="formData.levels"
                                                                v-model="newStudentLevel.level_id"
                                                                class="text_field background my-2"
                                                                item-value="id"
                                                                item-text="name"
                                                                outlined
                                                                dense
                                                                :rules="inputRules"
                                                                @input="populateSubLevels"
                                                            ></v-select>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs12 md4>
                                                            <div class="pa-3">
                                                            <div>Sub-Level<span class="error--text">*</span></div>
                                                            <v-select
                                                                :items="subLevels"
                                                                v-model="newStudentLevel.sub_level_id"
                                                                class="text_field background my-2"
                                                                item-value="id"
                                                                item-text="name"
                                                                outlined
                                                                dense
                                                                :rules="inputRules"
                                                            ></v-select>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-form>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn depressed class="primary text-none mt-3"
                                                    @click="saveStudentLevel" :disabled="!valid" :loading="loading">
                                                    Save
                                                    <v-icon right> mdi-content-save </v-icon>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-flex>
                                    </div>
                                </div>


                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </div>

        <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
    components: {
        logData,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            view: "default",
            loading: false,
            activateLoading: false,
            deactivateLoading: false,
            excelDialog: false,

            valid: true,
            message: "",
            color: "",
            delete: false,
            activate: false,
            confirm: false,
            lockEmail: false,
            studentIndex: null,
            snackbar: false,
            inputRules: [
                (v) => !!v || "Input is required"
            ],
            emailRules: [
                (v) =>
                    !v ||
                    /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
                        v
                    ) ||
                    "E-mail must be valid",
                (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
            ],

            searchTerm: null,
            searchLoader: false,
            searchButtonDisabled: false,
            filteredStudents: false,
            newStudent: {
                id: null,
                admission_no: null,
                account_no: null,
                admission_year: null,
                first_name: null,
                middle_name: null,
                last_name: null,
                email: null,
                phone_number: null,
            },
            assignedStudent: null,

            formData: null,
            logData: {
                message: null,
                model: "App\\Models\\Student",
            },

            activeTab: "details",

            searchUsers: null,
            assignedStudent: null,

            userStudentSearchTerm: null,
            userStudentSearchButtonDisabled: false,
            userStudentSearchLoader: false,

            studentLevelSearchTerm: null,
            studentLevelSearchButtonDisabled: false,
            studentLevelSearchLoader: false,

            userStudentLoader: false,
            filteredUserStudents: false,

            studentLevelLoader: false,
            filteredStudentLevels: false,

            studentTab: null,
            userStudentIndex: null,
            studentLevelIndex: null,

            userStudents: [],
            userStudentPagination: {
                search: " ",
                current_page: 1,
                per_page: 0,
                total: 0,
                visible: 10,
            },

            studentLevels: [],
            studentLevelPagination: {
                search: " ",
                current_page: 1,
                per_page: 0,
                total: 0,
                visible: 10,
            },

            newParent: {
                id: null,
                student_id: null,
                user_id: null,
            },
            newStudentLevel: {
                id: null,
                student_id: null,
                level_id: null,
                sub_level_id: null,
            },

            subLevels: [],

            userStudentView: "default",
            studentLevelView: "default",

            excelValid: true,
            excelLoading: false,
            fileRules: [(v) => !!v || "Kindly Select a File"],
            excelFile: null,

            years: [],

        };
    },
    watch: {
      searchUsers(val) {
        val && val !== this.newParent.user_id && this.findUser(val);
      },
    },
    created() {
        if (this.$can("student_view")) {
            this.startStudentLoader();
            this.fetchStudents(this.studentPagination.current_page);
            this.getFormData();
            this.populateYears();
        }
    },
    methods: {
        ...mapActions([
            "fetchStudents",
            "startStudentLoader",
            "stopStudentLoader",
            "filterStudents",

            "filterLogData",
            "openLogDataModel",
        ]),
        populateYears() {
            var max = new Date().getFullYear();
            var min = max - 100;
            var years = [];
    
            for (var i = max; i >= min; i--) {
            years.push(i);
            }
            this.years = years;
        },
        populateSubLevels(){
            this.subLevels = []
            for(var x=0; x<this.formData.levels.length; x++){
                if(this.formData.levels[x].id==this.newStudentLevel.level_id){
                    this.subLevels = this.formData.levels[x].sub_levels
                }
            }
        },
        getText(item) {
            var returnValue = "";

            if (item.first_name !== null) {
                returnValue += item.first_name + " ";
            }

            if (item.middle_name !== null) {
                returnValue += item.middle_name + " ";
            }

            if (item.last_name !== null) {
                returnValue += item.last_name;
            }

            if (item.email !== null) {
                returnValue += ' - ' + item.email;
            }

            if (item.phone !== null) {
                returnValue += '/' + item.phone;
            }

            return returnValue;
        },
        findUser(v) {
        this.loading = true;
        
          apiCall({
            url: "/api/user?type=search&search=" + this.searchUsers,
            method: "GET",
          })
            .then((resp) => {
              this.items = resp.data;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
       
      },
        enableSearch() {
            this.searchButtonDisabled = false;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredStudents = true;
            this.startStudentLoader();
            this.fetchStudents(1);
        },
        showStudent(student) {
            this.assignedStudent = student;
            this.changeView("show");
            this.changeUserStudentPage();
            this.changeStudentLevelPage();
        },
        editStudent(student) {
            this.lockEmail = true;
            this.newStudent.id = student.id;
            this.newStudent.admission_no = student.admission_no;
            this.newStudent.account_no = student.account_no;
            this.newStudent.admission_year = parseInt(student.admission_year);
            this.newStudent.first_name = student.first_name;
            this.newStudent.middle_name = student.middle_name;
            this.newStudent.last_name = student.last_name;
            this.newStudent.email = student.email;
            this.newStudent.phone_number = student.phone;           

            this.changeView("create");
        },
        viewLog(data) {
            this.logData.message = data.id;
            this.filterLogData(this.logData);
            this.openLogDataModel();
        },

        save() {
            if (this.$refs.form.validate()) {
                if (this.newStudent.id != null) {
                    confirm("Are You Sure You Want to Update Student") && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/student/" + this.newStudent.id,
                            data: this.newStudent,
                            method: "PUT",
                        })
                            .then((resp) => {
                                this.message = "Student Updated Successfully";
                                this.color = "orange";
                                this.loading = false;
                                this.snackbar = true;
                                this.confirm = false;
                                this.changeView("default");
                                this.fetchStudents(this.studentPagination.current_page);
                            })
                            .catch((error) => {
                                this.message = "An Error Occurred";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                    }
                } else {
                    confirm("Are You Sure You Want to Create Student?") && (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/student",
                            data: this.newStudent,
                            method: "POST",
                        })
                            .then((resp) => {
                                if (resp.status == 409) {
                                    this.message = resp.message
                                    this.color = "error";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.alertType = "error";
                                } else {
                                    this.message = "Student Added Successfully";
                                    this.color = "success";
                                    this.loading = false;
                                    this.snackbar = true;
                                    this.changeView("default");
                                    this.fetchStudents(1);
                                }
                            })
                            .catch((error) => {
                                this.message = "An Error Occurred Email";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                                this.changeView("default");
                                this.fetchStudents(1);
                            });
                    }
                }
            }
        },
        search() {
            if (this.searchTerm == null) {
                this.searchButtonDisabled = true;
            } else {
                this.searchLoader = true;
                this.searchButtonDisabled = false;
                this.startStudentLoader();
                apiCall({
                    url: "/api/student?type=search&search=" + this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterStudents(resp);
                        this.searchLoader = false;
                        this.stopStudentLoader();
                        this.filteredStudents = true;
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.searchLoader = false;
                        this.stopStudentLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        changeView(view) {
            if (view == "default") {
                this.lockEmail = false;
                this.newStudent.id = null;
                this.newStudent.admission_no = null;
                this.newStudent.account_no = null;
                this.newStudent.admission_year = null;
                this.newStudent.first_name = null;
                this.newStudent.middle_name = null;
                this.newStudent.last_name = null;
                this.newStudent.phone_number = null;
                this.newStudent.email = null;

            } else if (view == "create") {
                //   this.getFormData();
            } else if (view == "show") {
            }
            this.view = view;
        },
        changePage() {
            this.startStudentLoader();
            if (this.filteredStudents == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/student?page=" +
                        this.studentPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterStudents(resp);
                        this.stopStudentLoader();
                    })
                    .catch((error) => {
                        this.stopStudentLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchStudents(this.studentPagination.current_page);
            }
        },
        deleteStudent(item) {
            confirm("Are You Sure You Want to Delete Student?") && (this.delete = true);
            if (this.delete) {
                this.loading = true;
                this.studentIndex = item.id;
                apiCall({ url: "/api/student/" + item.id, method: "DELETE" })
                    .then((resp) => {
                        this.message = "Student Deleted Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.studentIndex = null;
                        this.fetchStudents(this.studentPagination.current_page);
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.studentIndex = null;
                        console.log(error.response);
                    });
            }
        },
        activateStudent(item) {
            confirm("Are You Sure You Want to Activate Student?") && (this.activate = true);
            if (this.activate) {
                this.activateLoading = true;
                this.studentIndex = item.id;
                this.newStudent.id = item.id;
                apiCall({
                    url: "/api/student?type=activate",
                    data: this.newStudent,
                    method: "POST"
                })
                    .then((resp) => {
                        this.message = "Student Account Activated Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.activateLoading = false;
                        this.activate = false;
                        this.studentIndex = null;
                        this.newStudent.id = null;
                        this.fetchStudents(this.studentPagination.current_page);
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.activateLoading = false;
                        this.activate = false;
                        this.studentIndex = null;
                        this.newStudent.id = null;
                        console.log(error.response);
                    });
            }
        },
        deactivateStudent(item) {
            confirm("Are You Sure You Want to Deactivate Student?") && (this.activate = true);
            if (this.activate) {
                this.deactivateLoading = true;
                this.studentIndex = item.id;
                this.newStudent.id = item.id;
                apiCall({
                    url: "/api/student?type=deactivate",
                    data: this.newStudent,
                    method: "POST"
                })
                    .then((resp) => {
                        this.message = "Student Account Deactivated Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.deactivateLoading = false;
                        this.activate = false;
                        this.studentIndex = null;
                        this.newStudent.id = null;
                        this.fetchStudents(this.studentPagination.current_page);
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.deactivateLoading = false;
                        this.activate = false;
                        this.studentIndex = null;
                        this.newStudent.id = null;
                        console.log(error);
                    });
            }
        },
        getFormData() {
            apiCall({
                url: "/api/student?type=adminFormData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "An Error Occurred; Couldn't Get Form Data";
                    this.color = "error";
                });
        },

        enableUserStudentSearch() {
            this.userStudentSearchButtonDisabled = false;
        },
        enableStudentLevelSearch() {
            this.studentLevelSearchButtonDisabled = false;
        },

        resetUserStudentSearch() {
            this.userStudentSearchTerm = null;
            this.filteredUserStudents = false;
            this.userStudentLoader = false;
            this.changeUserStudentPage();
        },
        resetStudentLevelSearch() {
            this.studentLevelSearchTerm = null;
            this.filteredStudentLevels = false;
            this.studentLevelLoader = false;
            this.changeStudentLevelPage();
        },

        changeUserStudentView(view) {
            if (view == "default") {
            this.newParent.id = null;
            this.newParent.student_id = null;
            this.newParent.user_id = null;
            } else if (view == "create") {
            } else if (view == "show") {
            }
            this.userStudentView = view;
        },
        changeStudentLevelView(view) {
            if (view == "default") {
                this.newStudentLevel.id = null;
                this.newStudentLevel.student_id = null;
                this.newStudentLevel.level_id = null;
                this.newStudentLevel.sub_level_id = null;
            } else if (view == "create") {
            } else if (view == "show") {
            }
            this.studentLevelView = view;
        },

        changeUserStudentPage() {
            this.userStudentLoader = true;
            if (this.filteredUserStudents == true) {
            this.userStudentSearchButtonDisabled = false;
            apiCall({
                url:
                "/api/user-student?student_id=" +
                this.assignedStudent.id +
                "&page=" +
                this.userStudentPagination.current_page +
                "&type=search&search=" +
                this.userStudentSearchTerm,
                method: "GET",
            })
                .then((resp) => {
                this.userStudentLoader = false;
    
                this.userStudents = resp.data;
                this.userStudentPagination.current_page =
                    resp.current_page;
                this.userStudentPagination.total = resp.total;
                this.userStudentPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                console.log(error);
                this.userStudentLoader = false;
                this.message = "An Error Occurred";
                this.color = "error";
                this.snackbar = true;
                });
            } else {
            apiCall({
                url:
                "/api/user-student?student_id=" +
                this.assignedStudent.id +
                "&page=" +
                this.userStudentPagination.current_page,
                method: "GET",
            })
                .then((resp) => {
                this.userStudentLoader = false;
    
                this.userStudents = resp.data;
                this.userStudentPagination.current_page =
                    resp.current_page;
                this.userStudentPagination.total = resp.total;
                this.userStudentPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                console.log(error);
                this.userStudentLoader = false;
                this.message = "An Error Occurred";
                this.color = "error";
                this.snackbar = true;
                });
            }
        },
        changeStudentLevelPage() {
            this.studentLevelLoader = true;
            if (this.filteredstudentLevels == true) {
            this.studentLevelSearchButtonDisabled = false;
            apiCall({
                url:
                "/api/student-level?student_id=" +
                this.assignedStudent.id +
                "&page=" +
                this.studentLevelPagination.current_page +
                "&type=search&search=" +
                this.studentLevelSearchTerm,
                method: "GET",
            })
                .then((resp) => {
                    this.studentLevelLoader = false;
        
                    this.studentLevels = resp.data;
                    this.studentLevelPagination.current_page =
                        resp.current_page;
                    this.studentLevelPagination.total = resp.total;
                    this.studentLevelPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                    console.log(error);
                    this.studentLevelLoader = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
            } else {
            apiCall({
                url:
                "/api/student-level?student_id=" +
                this.assignedStudent.id +
                "&page=" +
                this.studentLevelPagination.current_page,
                method: "GET",
            })
                .then((resp) => {
                    this.studentLevelLoader = false;
        
                    this.studentLevels = resp.data;
                    this.studentLevelPagination.current_page =
                        resp.current_page;
                    this.studentLevelPagination.total = resp.total;
                    this.studentLevelPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                    console.log(error);
                    this.studentLevelLoader = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
            }
        },

      userStudentSearch() {
        if (this.userStudentSearchTerm == null) {
          this.userStudentSearchButtonDisabled = true;
        } else {
          this.userStudentSearchLoader = true;
          this.userStudentSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/user-student?type=search&search=" +
              this.userStudentSearchTerm +
              "&student_id=" +
              this.assignedStudent.id,
            method: "GET",
          })
            .then((resp) => {
              this.userStudents = resp.data;
              this.userStudentPagination.current_page =
                resp.current_page;
              this.userStudentPagination.total = resp.total;
              this.userStudentPagination.per_page = resp.per_page;
  
              this.userStudentSearchLoader = false;
              this.filteredUserStudents = true;
            })
            .catch((error) => {
              this.userStudentSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      studentLevelSearch() {
        if (this.studentLevelSearchTerm == null) {
          this.studentLevelSearchButtonDisabled = true;
        } else {
          this.studentLevelSearchLoader = true;
          this.studentLevelSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/student-level?type=search&search=" +
              this.studentLevelSearchTerm +
              "&student_id=" +
              this.assignedStudent.id,
            method: "GET",
          })
            .then((resp) => {
              this.studentLevels = resp.data;
              this.studentLevelPagination.current_page =
                resp.current_page;
              this.studentLevelPagination.total = resp.total;
              this.studentLevelPagination.per_page = resp.per_page;
  
              this.studentLevelSearchLoader = false;
              this.filteredstudentLevels = true;
            })
            .catch((error) => {
              this.studentLevelSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },

      saveUserStudent() {
        if (this.$refs.userStudentForm.validate()) {
          confirm("Are you sure you want to add Parent?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newParent.student_id = this.assignedStudent.id;
            apiCall({
              url: "/api/user-student",
              data: this.newParent,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Parent Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.newParent.user_id = null;
                this.changeUserStudentView("default");
                this.changeUserStudentPage();
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      },
      saveStudentLevel() {
        if (this.$refs.studentLevelForm.validate()) {
          confirm("Are you sure you want to add Level?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newStudentLevel.student_id = this.assignedStudent.id;
            apiCall({
              url: "/api/student-level",
              data: this.newStudentLevel,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Student Level Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.newStudentLevel.user_id = null;
                this.changeStudentLevelView("default");
                this.changeStudentLevelPage();
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      },

      deleteUserStudent(item) {
        confirm("Are You Sure You Want to Remove Parent?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.userStudentIndex = item.id;
          apiCall({
            url: "/api/user-student/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Parent Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.userStudentIndex = null;
              this.changeUserStudentPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.userStudentIndex = null;
              console.log(error);
            });
        }
      },
      deleteStudentLevel(item) {
        confirm("Are You Sure You Want to Remove Level?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.studentLevelIndex = item.id;
          apiCall({
            url: "/api/student-level/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Level Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.studentLevelIndex = null;
              this.changeStudentLevelPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.studentLevelIndex = null;
              console.log(error);
            });
        }
      },

      activateUserStudent(item) {
        confirm(
          "Are You Sure You Want to Activate Parent?"
        ) && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.userStudentIndex = item.id;
          this.newParent.id = item.id;
          apiCall({
            url: "/api/user-student?type=activate",
            data: this.newParent,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Parent Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.userStudentIndex = null;
              this.newParent.id = null;
              this.changeUserStudentPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.userStudentIndex = null;
              this.newParent.id = null;
              console.log(error);
            });
        }
      },
      activateStudentLevel(item) {
        confirm(
          "Are You Sure You Want to Activate Level?"
        ) && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.studentLevelIndex = item.id;
          this.newStudentLevel.id = item.id;
          apiCall({
            url: "/api/student-level?type=activate",
            data: this.newStudentLevel,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Level Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.studentLevelIndex = null;
              this.newStudentLevel.id = null;
              this.changeStudentLevelPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.studentLevelIndex = null;
              this.newStudentLevel.id = null;
              console.log(error);
            });
        }
      },
  
      deactivateUserStudent(item) {
        confirm(
          "Are You Sure You Want to Deactivate Parent?"
        ) && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.userStudentIndex = item.id;
          this.newParent.id = item.id;
          apiCall({
            url: "/api/user-student?type=deactivate",
            data: this.newParent,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Parent Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.userStudentIndex = null;
              this.newParent.id = null;
              this.changeUserStudentPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.userStudentIndex = null;
              this.newParent.id = null;
              console.log(error);
            });
        }
      },
      deactivateStudentLevel(item) {
        confirm(
          "Are You Sure You Want to Deactivate Level?"
        ) && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.studentLevelIndex = item.id;
          this.newStudentLevel.id = item.id;
          apiCall({
            url: "/api/student-level?type=deactivate",
            data: this.newStudentLevel,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Level Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.studentLevelIndex = null;
              this.newStudentLevel.id = null;
              this.changeStudentLevelPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.studentLevelIndex = null;
              this.newStudentLevel.id = null;
              console.log(error);
            });
        }
      },

      saveExcel() {
        if (this.$refs.excelForm.validate()) {
            confirm("Are you sure you want to upload this excel?") &&
            (this.confirm = true);
            if (this.confirm) {
            this.excelLoading = true;
            let formData = new FormData();

            // files
            formData.append(
                "files",
                this.excelFile,
                this.excelFile.name
            );

            apiCall({
                url: "/api/student?type=excel",
                data: formData,
                method: "POST",
            })
                .then((resp) => {
                this.message = "Excel Uploaded Successfully";
                this.color = "orange";
                this.excelLoading = false;
                this.snackbar = true;
                this.confirm = false;
                this.excelDialog = false;
                this.fetchStudents(1);
                })
                .catch((error) => {
                this.excelLoading = false;
                this.confirm = false;
                });
            }
        }
        },

    },
    computed: {
        ...mapGetters(["students", "studentPagination", "studentLoader", "logDataModel"]),
        length: function () {
            return Math.ceil(
                this.studentPagination.total / this.studentPagination.per_page
            );
        },
        userStudentLength: function () {
            return Math.ceil(
            this.userStudentPagination.total /
                this.userStudentPagination.per_page
            );
        },
        studentLevelLength: function () {
            return Math.ceil(
            this.studentLevelPagination.total /
                this.studentLevelPagination.per_page
            );
        },
    },
};
</script>
  