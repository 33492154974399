import apiCall from '@/utils/api'

const state = {
	studentLevels: [],
    searchStudentLevels: [],
	allStudentLevels: [],
    studentLevelPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    searchStudentLevelPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    studentLevelLoader: false,
    searchStudentLevelLoader: false,
};

const getters = {
	studentLevels: (state) => state.studentLevels,
	allStudentLevels: (state) => state.allStudentLevels,
    studentLevelLoader: (state) => state.studentLevelLoader,
    studentLevelPagination: (state) => state.studentLevelPagination,

    searchStudentLevels: (state) => state.searchStudentLevels,
	searchStudentLevelLoader: (state) => state.searchStudentLevelLoader,	
    searchStudentLevelPagination: (state) => state.searchStudentLevelPagination,
}

const actions = {
	async fetchAllStudentLevels({commit}, page) {
		const response = await apiCall({url: `/api/part?type=all`, method: 'GET' });
		commit('setAllStudentLevels', response)
        commit('stopLoader', response)
	},
    async fetchSearchStudentLevels({commit}, page) {
		const response = await apiCall({url: `/api/part?type=recent&page=${page}`, method: 'GET' });
		commit('setSearchStudentLevels', response)
        commit('stopSearchStudentLevelLoader', response)
	},
    async fetchStudentLevels({commit}, page) {
		const response = await apiCall({url: `/api/part?type=recent&page=${page}`, method: 'GET' });
		commit('setStudentLevels', response)
        commit('stopLoader', response)
	},
    async filterStudentLevels({commit, state},resp){
		commit('setStudentLevels', resp)
	},
    async filterSearchStudentLevels({commit, state},resp){
		commit('setSearchStudentLevels', resp)
	},
	async startStudentLevelLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStudentLevelLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
    async startSearchStudentLevelLoader({commit, state},resp){
		commit('startSearchStudentLevelLoader', resp)
	},
	async stopSearchStudentLevelLoader({commit, state},resp){
		commit('stopSearchStudentLevelLoader', resp)
	},
};

const mutations = {
	setStudentLevels: (state, studentLevels) => {
		state.studentLevels = studentLevels.data
		state.studentLevelPagination.current_page = studentLevels.current_page
		state.studentLevelPagination.total = studentLevels.total
		state.studentLevelPagination.per_page = studentLevels.per_page
	},
	setAllStudentLevels: (state, studentLevels) => {
		state.allStudentLevels = studentLevels
	},
    startLoader: (state) => state.studentLevelLoader = true,
	stopLoader: (state) => state.studentLevelLoader = false,

    setSearchStudentLevels: (state, studentLevels) => {
		state.searchStudentLevels = studentLevels.data
		state.searchStudentLevelPagination.current_page = studentLevels.current_page
		state.searchStudentLevelPagination.total = studentLevels.total
		state.searchStudentLevelPagination.per_page = studentLevels.per_page
	},
    startSearchStudentLevelLoader: (state) => state.searchStudentLevelLoader = true,
	stopSearchStudentLevelLoader: (state) => state.searchStudentLevelLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
