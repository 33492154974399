<template>
  <div class="Profile">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Upload Profile Picture
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                label="Select Image"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mb-3 mx-3">
                <v-btn
                  depressed
                  block
                  class="primary text-none"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  Submit <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1200"
      v-model="profileDialog"
      v-if="formData!=null"
    >
      <v-card max-width="1200">
        <v-toolbar flat>
          Update Profile
          <v-spacer></v-spacer>
          <v-btn icon @click="profileDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="profileForm" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 md3>
              <div class="mx-2 mt-5">
                <div>First Name<span class="red--text">*</span></div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.first_name"
                  :rules="inputRules"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="mx-2 mt-5">
                <div>Middle Name</div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.middle_name"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="mx-2 mt-5">
                <div>Last Name<span class="red--text">*</span></div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.last_name"
                  :rules="inputRules"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="mx-2 mt-5">
                <div>Phone Number<span class="red--text">*</span></div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.phone"
                  :rules="inputRules"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mx-2">
                <v-btn
                  depressed
                  class="primary text-none mb-3 mt-5"
                  @click="updateProfile"
                  :loading="loading"
                >
                  Update <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
        </v-card-text>
        
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="passwordDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Change Account Password
          <v-spacer></v-spacer>
          <v-btn icon @click="passwordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="passwordForm" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <div class="mx-4 mt-5">
                <div>Current Password*</div>
                <v-text-field
                  outlined
                  dense
                  v-model="passwordData.current_password"
                  :rules="inputRules"
                  type="password"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-4 mt-5">
                <div>New Password*</div>
                <v-text-field
                  outlined
                  dense
                  v-model="passwordData.new_password"
                  :rules="passwordRules"
                  type="password"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-4 mt-5">
                <div>Confirm Password*</div>
                <v-text-field
                  outlined
                  dense
                  v-model="confirmpassword"
                  :rules="inputRules"
                  type="password"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mx-2 mt-5">
                <v-btn
                  depressed
                  class="primary text-none mb-3 mx-2"
                  @click="savePassword"
                  :loading="loading"
                  :disabled="!valid"
                >
                  Update <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>

    <v-layout column>
      <v-flex xs12>
        <div class="pa-5">
          <v-layout column>
            <v-flex xs12 class="mt-4">
              <v-card outlined class="pa-3" style="border-radius: 25px;">
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <div align="center">
                        <div>
                          <v-avatar size="156" class="primary title white--text" v-if="getProfile.display_img==null">
                            {{ getProfile.first_name[0] }}
                          </v-avatar>
                          <v-avatar size="156" class="primary title" v-else>
                            <img :src="path+'/storage/profile_pics/'+getProfile.display_img" alt="prof_pic">
                          </v-avatar>
                        </div>
                        <div>
                          <v-btn depressed class="text-none mt-5 accent white--text" @click="imageDialog = true">
                            Upload Profile Pic
                          </v-btn>
                        </div>
                        <div>
                          <v-btn depressed class="text-none mt-3 secondary white--text" @click="passwordDialog = true">
                    Change Password
                  </v-btn>
                          </div>
                      
                      </div>
                    </v-flex>
                    <v-flex xs12 md8>
                      <v-layout column>
                        <v-flex xs12>
                          <b class="title mt-5"
                            >{{ getProfile.first_name }} {{ getProfile.middle_name }}
                            {{ getProfile.last_name }}</b
                          >
                          <v-btn icon @click="profileDialog = true"><v-icon>mdi-pen</v-icon></v-btn>
                        </v-flex>
                        
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <b>Email:</b>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{ getProfile.email }}
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <b>Phone No:</b>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{ getProfile.phone }}
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import { USER_REQUEST } from "@/store/actions/user";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,

      editor: ClassicEditor,
      editorConfig: {},

      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "posts",
      loading: false,
      valid: true,
      message: "",
      color: "",
      confirm: false,
      snackbar: false,

      formData: null,

      profileDialog: false,

      imageDialog: false,
      passwordDialog: false,
      imageLoading: false,
      imageFile: null,
      imageValid: true,

      confirmpassword: "",
      fileRules: [(v) => !!v || "Kindly Select a File"],
      passwordRules: [
        (v) => !!v || "Password is Required",
        (v) => (v && v.length >= 8) || "Minimum 8 Characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must Have at Least One Uppercase Character",
        (v) => /(?=.*\d)/.test(v) || "Must Have at Least One Number",
        (v) => /([!@$%.])/.test(v) || "Must Have at Least One Special Character",
      ],
      inputRules: [
          v => v.length >= 3 || 'Minimum length is 3 characters'
      ],

      user: {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
      },

      passwordData: {
        current_password: null,
        new_password: null,
      },

    };
  },
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.assignProfile()
    this.getFormData();
    this.fetchUserPosts(1);
  },
  methods: {
    ...mapActions([
      
    ]),
    getFormData() {
      apiCall({
        url: "/api/user?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "an error occurred";
          this.color = "error";
        });
    },
    assignProfile(){
      this.user.id = this.getProfile.id
      this.user.first_name = this.getProfile.first_name
      this.user.middle_name = this.getProfile.middle_name
      this.user.last_name = this.getProfile.last_name
      this.user.email = this.getProfile.email
      this.user.phone = this.getProfile.phone

      
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },

    savePassword() {
      if (this.$refs.passwordForm.validate()) {
        if (this.passwordData.new_password != this.confirmpassword) {
          this.message = "New Password and Confirm Password do not Match";
          this.color = "error";
          this.snackbar = true;
        } else {
          confirm("Are you sure you want to change your account password") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/user?type=password",
              data: this.passwordData,
              method: "POST",
            })
              .then((resp) => {
                this.snackbar = true;
                this.message = "Password Updated Successfully";
                this.color = "success";
                this.confirm = false;
                this.loading = false;
                this.passwordDialog = false;
              })
              .catch((error) => {
                this.snackbar = true;
                this.confirm = false;
                this.message =
                  "Current Password does not match with our records";
                this.color = "error";
                this.loading = false;
              });
          }
        }
      }
    },
    updateProfileSwitch(){
      this.loading = true;
        apiCall({
          url: "/api/user/" + this.user.id + "?type=user",
          data: this.user,
          method: "PUT",
        })
          .then((resp) => {
            this.message = "Profile Updated Successfully";
            this.color = "orange";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;
            this.profileDialog = false;
            this.$store.dispatch(USER_REQUEST);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
          });
    },
    updateProfile(){
      if (this.$refs.profileForm.validate()) {
        confirm("Are you sure you want to update your profile?") && (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          apiCall({
            url: "/api/user/" + this.user.id + "?type=user",
            data: this.user,
            method: "PUT",
          })
            .then((resp) => {
              this.message = "Profile Updated Successfully";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.profileDialog = false;
              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);

        apiCall({
          url: "/api/user?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "profile Picture Uploaded Successfully";
            this.color = "success";
            this.$store.dispatch(USER_REQUEST);
            this.imageLoading = false;
            this.imageDialog = false;
            this.imageFile = null
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },


  },
  computed: {
    ...mapGetters([
      "getProfile", 
      "darkState", 
    
    ]),
    formatDateOfBirth() {
      if (this.user.profile.dob != null) {
        const d = new Date(this.user.profile.dob);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
  },
};
</script>
