import { render, staticRenderFns } from "./walletLog.vue?vue&type=template&id=0c9c49b2&"
import script from "./walletLog.vue?vue&type=script&lang=js&"
export * from "./walletLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports