<template>
    <div class="walletLogs">
        <div class="pa-5">
            <v-layout column>
                <v-card elevation="0">
                    <v-progress-linear v-if="walletLogLoader" height="1" indeterminate color="primary">
                    </v-progress-linear>
                    <v-flex xs12 class="mt-5">
                        <div v-if="walletLogs.length == 0">
                            <v-card elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md1>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    <v-icon large class="primary--text">
                                                        mdi-alert-circle-outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    No Transactions Found
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                        <div v-else>
                            <v-layout row wrap>
                                <v-flex xs12 md3>
                                    <v-card shaped elevation="0" class="mx-auto my-2" color="#26c6da" dark max-width="400">
                                        <v-card-title>
                                            <v-icon large left>
                                                mdi-credit-card
                                            </v-icon>
                                            <span class="text-h6 font-weight-light">Wallet</span>
                                        </v-card-title>

                                        <v-card-text class="text-h5 font-weight-bold">
                                            <div class="pa-5">
                                                <v-layout row wrap>
                                                    <v-flex xs6>
                                                        Balance:
                                                    </v-flex>
                                                    <v-flex xs6>
                                                        <div align="right">
                                                            Kshs {{ allWallets.amount | formatNumber }}
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </div>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-list-item class="grow">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ allWallets.wallet_log.created_at |
                                                        moment("DD/MM/YY:hh:mm a") }}</v-list-item-title>
                                                </v-list-item-content>

                                                <v-row align="center" justify="end">
                                                    <span class="subheading mr-2">{{ allWallets.wallet_log.amount |
                                                        formatNumber }}</span>
                                                    <v-icon class="mr-1 white--text"
                                                        v-if="allWallets.wallet_log.wallet_transaction_type.name == 'Credit'">
                                                        mdi-arrow-up-bold
                                                    </v-icon>
                                                    <v-icon class="mr-1 white--text"
                                                        v-if="allWallets.wallet_log.wallet_transaction_type.name == 'Debit'">
                                                        mdi-arrow-down-bold
                                                    </v-icon>


                                                </v-row>
                                            </v-list-item>
                                        </v-card-actions>
                                    </v-card>                                    
                                </v-flex>
                                <v-flex xs12 md9>
                                        <div class="hidden-sm-and-down">
                                            <v-card elevation="0" class="pa-2">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead class="header">
                                                            <tr class="accent">
                                                                <th class="text-left text--text">Transaction Number</th>
                                                                <th class="text-left text--text">Transaction Code</th>
                                                                <th class="text-left text--text">Amount</th>
                                                                <th class="text-right text--text">Opening Balance</th>
                                                                <th class="text-right text--text">Closing Balance</th>
                                                                <th class="text-left text--text">Transaction Type</th>
                                                                <th class="text-left text--text">Fund Source</th>
                                                                <th class="text-left text--text">Item</th>
                                                                <th class="text-left text--text">Date Created</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in walletLogs" :key="item.id">
                                                                <td>
                                                                    {{ item.transaction_number }}
                                                                </td>
                                                                <td>
                                                                    {{ item.transaction_code }}
                                                                </td>
                                                                <td>
                                                                    <div v-if="item.wallet_transaction_type.name == 'Credit'"
                                                                        class="green--text" align="right">
                                                                        +{{ item.amount | formatNumber }}
                                                                    </div>
                                                                    <div v-if="item.wallet_transaction_type.name == 'Debit'"
                                                                        class="red--text" align="right">
                                                                        -{{ item.amount | formatNumber }}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div align="right">
                                                                        {{ item.opening_balance | formatNumber }}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div align="right">
                                                                        {{ item.closing_balance | formatNumber }}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{ item.wallet_transaction_type.name }}
                                                                </td>
                                                                <td>
                                                                    {{ item.wallet_transaction_source.name }}
                                                                </td>
                                                                <td>
                                                                    <div v-if="item.wallet_transaction_log!=null">
                                                                        {{
                                                                            item.wallet_transaction_log.transaction_log.sale_part_stock.sale_part.part.name
                                                                        }}
                                                                    </div>
                                                                    <div v-else>
                                                                        N/A
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <div>
                                                <v-layout column>
                                                    <template v-for="(walletLog, index) in walletLogs">
                                                        <div :key="index">
                                                            <v-flex xs12 class="mb-2">
                                                                <v-card elevation="0" style="border-radius: 25px;" outlined>
                                                                    <div class="pa-5">
                                                                        <v-layout column>
                                                                            <v-flex xs12>
                                                                                <v-layout row wrap>
                                                                                    <v-flex xs11>
                                                                                        <div class="title primary--text">
                                                                                            <b>{{
                                                                                                walletLog.transaction_number
                                                                                            }}</b>
                                                                                        </div>
                                                                                    </v-flex>
                                                                                </v-layout>
                                                                            </v-flex>
                                                                            <v-divider class="mt-2"></v-divider>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <v-layout column>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Transaction Code</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                {{
                                                                                                    walletLog.transaction_code
                                                                                                }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Amount</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                <div v-if="walletLog.wallet_transaction_type.name == 'Credit'"
                                                                                                    class="green--text"
                                                                                                    align="left">
                                                                                                    +{{ walletLog.amount |
                                                                                                        formatNumber }}
                                                                                                </div>
                                                                                                <div v-if="walletLog.wallet_transaction_type.name == 'Debit'"
                                                                                                    class="red--text"
                                                                                                    align="left">
                                                                                                    -{{ walletLog.amount |
                                                                                                        formatNumber }}
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Opening Balance</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                <div align="left">
                                                                                                    {{
                                                                                                        walletLog.opening_balance
                                                                                                        | formatNumber }}
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Closing Balance</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                <div align="left">
                                                                                                    {{
                                                                                                        walletLog.closing_balance
                                                                                                        | formatNumber }}
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Transaction Type</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                {{
                                                                                                    walletLog.wallet_transaction_type.name
                                                                                                }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Fund Source</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                {{
                                                                                                    walletLog.wallet_transaction_source.name
                                                                                                }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Item</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                <div v-if="walletLog.wallet_transaction_log!=null">
                                                                                                    {{
                                                                                                        walletLog.wallet_transaction_log.transaction_log.sale_part_stock.sale_part.part.name
                                                                                                    }}
                                                                                                </div>
                                                                                                <div v-else>
                                                                                                    N/A
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>



                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Status</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                <div
                                                                                                    v-if="walletLog.status == 1">
                                                                                                    Active</div>
                                                                                                <div v-else>Inactive</div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md4>
                                                                                                <b>Date Created</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md8>
                                                                                                {{ walletLog.created_at  | moment("DD/MM/YYYY - hh:mm a") }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                </v-layout>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </div>
                                                                </v-card>
                                                            </v-flex>
                                                        </div>
                                                    </template>
                                                </v-layout>
                                            </div>
                                        </div>
                                        <div align="left" class="mt-5">
                                            <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                                v-model="walletLogPagination.current_page" @input="changePage()" circle>
                                            </v-pagination>
                                        </div>
                                    </v-flex>
                            </v-layout>

                        </div>
                    </v-flex>
                </v-card>
            </v-layout>
        </div>
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0.00");
});

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,

        };
    },

    created() {
        this.startWalletLogLoader();
        this.fetchAllWallets();
        this.fetchWalletLogs(this.walletLogPagination.current_page);
        // this.getFormData();
        // this.populateYears();
        this.startWalletLoader()

    },
    methods: {
        ...mapActions([
            "fetchAllWallets",
            "startWalletLoader",

            "fetchWalletLogs",
            "startWalletLogLoader",
            "stopWalletLogLoader",
            "filterWalletLogs",
        ]),

        changePage() {
            this.startWalletLogLoader();
            if (this.filteredwalletLogas == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/learning-area?page=" +
                        this.walletLogPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterWalletLogs(resp);
                        this.stopWalletLogLoader();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.stopWalletLogLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchWalletLogs(this.walletLogPagination.current_page);
            }
        },

    },
    computed: {
        ...mapGetters([
            "walletLogs",
            "walletLogPagination",
            "walletLogLoader",
            "logDataModel",

            "allWallets"
        ]),
        length: function () {
            return Math.ceil(
                this.walletLogPagination.total / this.walletLogPagination.per_page
            );
        },
    },
};
</script>
  