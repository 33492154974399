import apiCall from '@/utils/api'

const state = {
	levels: [],
	allLevels: [],
    levelPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    levelLoader: false
};

const getters = {
	levels: (state) => state.levels,
	allLevels: (state) => state.allLevels,
    levelLoader: (state) => state.levelLoader,
    levelPagination: (state) => state.levelPagination
}

const actions = {
	async fetchAllLevels({commit}, page) {
		const response = await apiCall({url: `/api/level?type=all`, method: 'GET' });
		commit('setAllLevels', response)
        commit('stopLoader', response)
	},
    async fetchLevels({commit}, page) {
		const response = await apiCall({url: `/api/level?page=${page}`, method: 'GET' });
		commit('setLevels', response)
        commit('stopLoader', response)
	},
    async filterLevels({commit, state},resp){
		commit('setLevels', resp)
	},
	async startLevelLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLevelLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLevels: (state, levels) => {
		state.levels = levels.data
		state.levelPagination.current_page = levels.current_page
		state.levelPagination.total = levels.total
		state.levelPagination.per_page = levels.per_page
	},
	setAllLevels: (state, levels) => {
		state.allLevels = levels
	},
    startLoader: (state) => state.levelLoader = true,
	stopLoader: (state) => state.levelLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
