<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-app-bar outlined elevation="1" color="header" app>
        <v-row align="center">
          <v-app-bar-nav-icon v-if="isAuthenticated" @click.stop="drawer = !drawer" class="primary--text">
          </v-app-bar-nav-icon>
          <v-img v-if="!isAuthenticated" max-width="50" class="ml-1" src="img/logo.gif">
            </v-img>
          <v-toolbar-title v-if="!isAuthenticated" class="ml-1 hidden-sm-and-down">Strathmore School Bookshop</v-toolbar-title>
          
          <v-spacer></v-spacer>
          <v-text-field v-if="isAuthenticated" class="search_field background hidden-sm-and-down mr-5" outlined dense rounded
            v-model="searchPartPagination.search" append-icon="mdi-magnify" required @click:append="search()"
            v-on:keyup.enter="search()"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small depressed class="primary white--text mr-2 text-none"
            @click="changeLoginCardState" v-if="!isAuthenticated">Log In
          </v-btn>

          <v-btn v-if="isAuthenticated && ($can('wishlist_view'))" icon to="/wishlist">
            <div v-if="wishlistPagination.total != 0">
              <v-badge
                overlap
                bordered
                color="secondary white--text"
                :content="wishlistPagination.total"
              >
                <v-icon dark class="primary--text"> mdi-heart </v-icon>
              </v-badge>
            </div>
            <div v-else>
              <v-icon dark class="primary--text"> mdi-heart </v-icon>
            </div>
          </v-btn>
          <v-btn v-if="isAuthenticated && ($can('cart_view'))" icon to="/cart">
            <div v-if="cartPagination.total != 0">
              <v-badge
                overlap
                bordered
                color="secondary white--text"
                :content="cartPagination.total"
              >
                <v-icon dark class="primary--text"> mdi-cart </v-icon>
              </v-badge>
            </div>
            <div v-else>
              <v-icon dark class="primary--text"> mdi-cart </v-icon>
            </div>
          </v-btn>
          <v-menu left offset-y v-if="isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="mr-1" v-bind="attrs" v-on="on">
              <div v-if="notifications.length == 0">
                
                  <v-icon class="primary--text"> mdi-bell </v-icon>
                
              </div>
              <div v-else>
                <v-badge 
                  overlap
                  bordered
                  color="secondary white--text" 
                  :content="notifications.length"                 
                >
                    <v-icon class="primary--text"> mdi-bell </v-icon>
                </v-badge>
              </div>
            </v-btn>
            </template>
            <v-list two-line>
              <template v-for="notification in notifications.slice(0, 5)">
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\RefundDeniedInternal'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Refund Request Denied
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\RefundApprovedInternal'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Refund Request Approved
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\RequestRefund'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Refund Request
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\RequestRefundInternal'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Refund Request
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\RequestRefundExternal'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Refund Request
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\ItemIssued'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Item Issued
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - {{
                      notification.data.part.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="notification.type == 'App\\Notifications\\ConfirmSaleInternal'
                  ">
                  <v-list-item-content>
                    <v-list-item-title>Online Sale
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.sale.sale_no }} - 
                     {{ notification.data.user.first_name }} {{ notification.data.user.middle_name }} {{ notification.data.user.last_name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="notifications.length == 0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>No New Notification</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-divider></v-divider>
              <template>
                <v-list-item to="/notifications">
                  <v-list-item-content>
                    <v-list-item-title>All Notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-menu left offset-y v-if="isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="text-none">
                <v-avatar size="36" v-if="getProfile.profile_pic != null">
                  <img :src="path + '/profile_pics/' + getProfile.profile_pic" alt="PPIC" />
                </v-avatar>
                <v-avatar size="46" v-else>
                  <v-icon class="primary--text"> mdi-account-circle </v-icon>
                </v-avatar>
                <!-- <div class="ml-2">{{ getProfile.first_name }}</div>
                <v-icon>mdi-chevron-down</v-icon> -->
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-avatar size="46" class="primary white--text" v-if="getProfile.display_img == null">
                    {{ getProfile.first_name[0] }}
                  </v-avatar>
                  <v-avatar size="46" class="primary white--text" v-else>
                    <img :src="path + '/storage/profile_pics/' + getProfile.display_img" alt="prof_pic">
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ getProfile.first_name }}
                    {{ getProfile.last_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item router to="/profile">
                <v-list-item-title class="body-2">Profile</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/wallet">
                <v-list-item-title class="body-2">Wallet</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/sales">
                <v-list-item-title class="body-2">Sale History</v-list-item-title>
              </v-list-item>
              <v-list-item @click="signOut">
                <v-list-item-title class="body-2">Sign Out</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                  <template v-slot:activator="{ on }">

                    <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                      <v-icon>mdi-moon-waxing-crescent</v-icon>
                    </v-btn>

                  </template>
                  <span>Dark Mode</span>
                </v-tooltip>

                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">

                    <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                      <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                    </v-btn>

                  </template>
                  <span>Light Mode</span>
                </v-tooltip>
              </v-list-item> -->
            </v-list>
          </v-menu>
          <!-- <v-btn small depressed class="secondary primary--text mt-4 hidden-sm-and-down text-none"
            @click="changeLoginCardState">Login
          </v-btn> -->
        </v-row>
    </v-app-bar>
    <v-navigation-drawer disable-resize-watcher app v-model="drawer" v-if="isAuthenticated">
      <v-list-item class="my-5">
        <v-layout row wrap>
            <v-flex xs3>
              <a @click="goToHome()">
                <v-img max-width="45" class="my-2" src="img/logo.gif">
                </v-img>
              </a>
            </v-flex>
            <v-flex xs9>
              <div class="title primary--text">Strathmore School</div>
              <div class="secondary--text">Bookshop</div>
            </v-flex>
          </v-layout>
        </v-list-item>
        
      <v-text-field v-if="isAuthenticated" class="search_field background mx-2 my-2 hidden-md-and-up" outlined dense
        v-model="searchPartPagination.search" append-icon="mdi-magnify" required @click:append="search()"
        v-on:keyup.enter="search()"></v-text-field>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item router to="/home" class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-shape">
          <template v-slot:activator>
            <v-list-item-title>Categories</v-list-item-title>
          </template>
          <template v-for="(category, index) in allCategories">
            <v-list-group :value="false" no-action sub-group link to="books">

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(sub_category, i) in category.sub_categories" :key="i" link :to="'/categories/'+category.slug+'/'+sub_category.slug">
                <v-list-item-title v-text="sub_category.name"></v-list-item-title>
              </v-list-item>


            </v-list-group>
          </template>

        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-chair-school">
          <template v-slot:activator>
            <v-list-item-title>Levels</v-list-item-title>
          </template>
          <template v-for="(level, index) in allLevels">
            <v-list-group :value="false" no-action sub-group>

              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ level.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(sub_level, i) in level.sub_levels" :key="i" link  :to="'/levels/'+level.slug+'/'+sub_level.slug">
                <v-list-item-title v-text="sub_level.name"></v-list-item-title>
              </v-list-item>


            </v-list-group>
          </template>

        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-human-male-board">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Learning Areas</v-list-item-title>
            </v-list-item-content>
            
          </template>
          
            <v-list-item 
            v-for="(learningArea, index) in allLearningAreas"
            router :to="'/learning-areas/'+ learningArea.slug" link>
            <v-list-item-icon>
          
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="learningArea.name"></v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
            
        

        </v-list-group>      

        <v-list-group :value="false" prepend-icon="mdi-security" class="primary--text"
          v-if="isAuthenticated && ($can('role_view') || $can('user_view') || $can('student_view') || $can('manufacturer_view') || $can('supplier_view') || $can('category_view') || $can('level_view') || $can('learning_area_view') || $can('part_view') || $can('sale_view') || $can('sale_refund_view')|| $can('ad_view'))">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Administration</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- <v-list-item router to="/dashboard" v-if="$can('dashboard_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Dashboard</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>

          </v-list-item> -->
          <!-- <v-list-item router to="/logs" v-if="$can('log_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Logs</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-notebook-edit</v-icon>
            </v-list-item-icon>

          </v-list-item> -->
          <!-- <v-list-item router to="/reports" v-if="$can('report_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Reports</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-chart-box</v-icon>
            </v-list-item-icon>

          </v-list-item> -->

          <v-list-item router to="/roles" v-if="$can('role_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Roles</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-hard-hat</v-icon>
            </v-list-item-icon>

          </v-list-item>
          <v-list-item router to="/users" v-if="$can('user_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Users</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-multiple</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/students" v-if="$can('student_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Students</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-school</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item router to="/manufacturers-admin" v-if="$can('manufacturer_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Manufacturers</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-cogs</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/suppliers-admin" v-if="$can('supplier_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Suppliers</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-truck-delivery</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/categories-admin" v-if="$can('category_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Categories</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-shape</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/levels-admin" v-if="$can('level_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Levels</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-chair-school</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/learning-areas-admin" v-if="$can('learning_area_view')">
            <v-list-item-content>
              <v-list-item-title class="ml-14">Learning Areas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-human-male-board</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item router to="/parts-admin" v-if="$can('part_view')">

            <v-list-item-content>
              <v-list-item-title class="ml-14">Parts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/sales-admin" v-if="$can('sale_view')">

            <v-list-item-content>
              <v-list-item-title class="ml-14">Sales</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-receipt-text-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/refunds-admin" v-if="$can('sale_refund_view')">

            <v-list-item-content>
              <v-list-item-title class="ml-14">Refunds</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-cash-refund</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item router to="/ads-admin" v-if="$can('ad_view')">

            <v-list-item-content>
              <v-list-item-title class="ml-14">Ads</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-billboard</v-icon>
            </v-list-item-icon>
            </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-chart-timeline" class="primary--text"
        v-if="$can('report_view')">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                Reports
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(item, index) in reportsPages">
            <v-list-item :key="index" router :to="item.route"
              v-if="$can(item.permission)">
              <v-list-item-content>
                <v-list-item-title class="ml-14">
                  {{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item v-for="item in items" :key="item.title" v-if="isAuthenticated && $can(item.permission)"
          :to="item.route" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="changeLoginCardState" v-if="!isAuthenticated" link>
          <v-list-item-icon>
            <v-icon>mdi-account-lock-open</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Log In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
// import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home-outline", route: "/home", permission: "public_home_view" },
      ],
      searchData: {
        term: null
      },

      reportsPages: [
        {
          title: "Parts",
          icon: "mdi-book-open-page-variant",
          route: "/reports/parts",
          permission: "report_view",
        },
        {
          title: "Stock",
          icon: "mdi-archive",
          route: "/reports/stock",
          permission: "report_view",
        },
        {
          title: "Daily Stock",
          icon: "mdi-calendar-outline",
          route: "/reports/daily-stock",
          permission: "report_view",
        },
        {
          title: "Purchases",
          icon: "mdi-cart",
          route: "/reports/purchase",
          permission: "report_view",
        },
        {
          title: "Sales",
          icon: "mdi-receipt-text-outline",
          route: "/reports/sales",
          permission: "report_view",
        },
        {
          title: "Sale Items",
          icon: "mdi-receipt-text-check-outline",
          route: "/reports/sale-items",
          permission: "report_view",
        },
        {
          title: "Mpesa",
          icon: "mdi-cellphone-sound",
          route: "/reports/mpesa",
          permission: "report_view",
        },
        {
          title: "Cash",
          icon: "mdi-cash-100",
          route: "/reports/cash",
          permission: "report_view",
        },
        {
          title: "Wallets",
          icon: "mdi-credit-card",
          route: "/reports/wallet",
          permission: "report_view",
        },
        {
          title: "Wallet Logs",
          icon: "mdi-credit-card-clock",
          route: "/reports/wallet-logs",
          permission: "report_view",
        },
      ],
      
    };
  },
  watch: {
    darkState() {
      this.handledarkmode();
    },
  },
  created() {
    this.initialize();
    if (this.isAuthenticated) {
      this.fetchCarts(1);
      this.fetchWishlists(1);
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
  methods: {
    ...mapActions([
      "changeDarkState",
      "changeLoginCardState",

      "startSearchPartLoader",
      "stopSearchPartLoader",
      "filterSearchParts",

      "commitNotification",
      "commitNotifications",

      "fetchAllCategories",
      "fetchAllLearningAreas",
      "fetchAllLevels",

      "fetchCarts",
      "fetchWishlists"
    ]),
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
    },
    goToHome() {
      this.$router.push("/home");
    },
    initialize() {
      Echo.private(`App.Models.User.${this.getProfile.id}`).notification(
        (notification) => {
          this.commitNotification(notification)
          if (notification.type == "App\\Notifications\\ApplicationAccepted") {
            this.message =
              "Eligible for " + notification.data.event.title;
            this.color = "success";
            this.$store.dispatch("fetchApplications");
          }


          //this.notifications = this.notifications.concat(notification);
        }
      );
      this.fetchNotifications();
      this.fetchAllCategories()
      this.fetchAllLearningAreas()
      this.fetchAllLevels()
    },
    fetchNotifications() {
      apiCall({ url: "/api/notifications", method: "GET" })
        .then((resp) => {
          this.commitNotifications(resp);
          // var i = 0
          // for(i; i<=resp.length; i++){
          // 	if(resp[i].type == 'App\\Notifications\\AmbulanceDispatched'){
          // 		this.addDispatchData(resp[i])
          // 	}
          // }
        })
        .catch((error) => { });
    },
    readNotification(item) {
      const index = this.notifications.indexOf(item);
      this.notifications.splice(index, 1);
      apiCall({ url: "/api/notificationRead/" + item.id, method: "GET" })
        .then((resp) => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.response);
        });
      if (item.type == "App\\Notifications\\RefundDeniedInternal") {
        this.$router.push("/sales-admin");
      }
      if (item.type == "App\\Notifications\\RefundApprovedInternal") {
        this.$router.push("/sales-admin");
      }
      if (item.type == "App\\Notifications\\RequestRefund") {
        this.$router.push("/sales");
      }
      if (item.type == "App\\Notifications\\RequestRefundInternal") {
        this.$router.push("/refunds-admin");
      }
      if (item.type == "App\\Notifications\\RequestRefundExternal") {
        this.$router.push("/sales");
      }
      if (item.type == "App\\Notifications\\ItemIssued") {
        this.$router.push("/sales");
      }
      if (item.type == "App\\Notifications\\ConfirmSaleInternal") {
        this.$router.push("/sales-admin");
      }
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    search() {
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }

      this.startSearchPartLoader();
      this.searchData.term = this.searchPartPagination.search

      apiCall({
        url:
          "/api/part?type=publicSearch&page=1",
          data: this.searchData,
          method: "POST",
      })
        .then((resp) => {
          this.filterSearchParts(resp);
          this.stopSearchPartLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopSearchPartLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "darkState",
      "isAuthenticated",
      "searchPartPagination",
      "notifications",

      "allCategories",
      "allLearningAreas",
      "allLevels",

      "cartPagination",
      "wishlistPagination",
    ]),
  },
};
</script>
