<template>
  <v-app>
    <Navbar v-if="['PageNotFound', 'ResetPass', 'Redirector'].indexOf($route.name) == -1" />
      <v-main class="header">
        <router-view></router-view>
        
      </v-main>
      <!-- <FloatingButton v-if="isAuthenticated"></FloatingButton> -->
      <!-- <ContactPopUp v-model="contactCardState" /> -->
    <Footer v-if="$route.path === '/'"/>
  </v-app>
</template>

<script>
import FloatingButton from "@/components/system/FloatingButton";
import ContactPopUp from "@/components/system/ContactPopUp";
import Navbar from "@/components/layout/navbar";
import Footer from '@/components/layout/footer'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
    FloatingButton,
    ContactPopUp
  },
  data: () => ({
    //
  }),
  created() {
    this.setTheme()
    // i18n.locale = this.language;
    if (this.darkState == true) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  
  methods: {
    ...mapActions([
            
    ]),
    setTheme(){
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated", 
      // "contactCardState", 
      "darkState", 
      "language"]),
  },
};
</script>
