<template>
    <div>
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-dialog
        v-model="contactCardState"
        persistent
        transition="dialog-bottom-transition"
        max-width="1150"
      >
        <v-card :loading="loading" class="elevation-1" max-width="1150">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="2"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-toolbar flat color="transparent">
            <div class="title">Contact Us</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="changeContactCardState">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mx-3">
                <v-layout row wrap>
                    <v-flex xs12>
                  <div class="mt-5 ml-1">
                    <b>Subject</b
                    ><span class="red--text">*</span>
                  </div>
                  <v-text-field
                    outlined
                    dense
                    v-model="contactData.subject"
                    :rules="inputRules"
                    class="background login_field mx-1"
                  ></v-text-field>
                </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div class="ml-1">
                      <b>Message</b><span class="red--text">*</span>
                    </div>
                    <div class="black--text">
                      <ckeditor
                        :editor="editor"
                        v-model="contactData.message"
                        :config="editorConfig"
                        :rules="inputRules"
                      ></ckeditor>
                    </div>
                  </v-flex>
                </v-layout>
  
                
              </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  
                  large
                  color="primary"
                  :disabled="!valid"
                  @click="create"
                  :loading="loading"
                  class="text-none my-2 mr-2"
                >
                  Send
                  <v-icon dark right>mdi-send</v-icon>
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  Vue.use(CKEditor);
  
  export default {
    name: "ContactPopUp",
  
    data: () => {
      return {
        alertType: "",
        snackbar: false,
        message: "",
        color: "",
        loading: false,
        valid: true,
  
        editor: ClassicEditor,
        editorConfig: {},
  
        inputRules: [(v) => !!v || "Input is Required"],

        contactData: {
            subject: null,
            message: null,
        },
      };
    },
    watch: {
      contactCardState() {
        
      },
    },
    methods: {
      ...mapActions(["changeContactCardState"]),
      reset() {
        this.$refs.form.reset();
      },
      create() {
        if (this.$refs.form.validate()) {
           if (this.contactData.message == null) {
            this.message = "Please add your message";
            this.color = "error";
            this.snackbar = true;
          } else {
            this.loading = true;
            apiCall({
              url: "/api/contact-us",
              data: this.contactData,
              method: "POST",
            })
              .then((resp) => {
                this.message =
                  "Message sent Successfully.";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
  
                this.contactData.subject = null,
                this.contactData.message = null,
                this.changeContactCardState();
              })
              .catch((error) => {
                this.message = "An Error Occurred. Please try again..";
                this.loading = false;
                this.snackbar = true;
                this.alertType = "error";
              });
          }
        }
      },
    },
    computed: {
      ...mapGetters(["contactCardState"]),
    },
  };
  </script>
  