<template>
    <div class="TermsOfUse">
        <v-container>
            <v-card elevation="0" class="pa-16 my-10">
                <div class="headline">
                    <b>TERMS OF USE</b>
                </div>
                <div class="mt-5">
                    <b>Last updated July 15, 2023</b>
                </div>
                <div class="mt-10">
                    <b class="body-2">AGREEMENT TO OUR LEGAL TERMS</b>
                </div>
                <div class="title mt-10">
                    <b>TABLE OF CONTENTS</b>
                </div>
        </v-card>
    </v-container>
</div></template>
<script>
export default {
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        }
    }
}
</script>